<template>
  <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasSidebar" aria-labelledby="offcanvasSidebarLabel">
    <button class="position-absolute btn btn-primary " style="top: 5px; right: -45px;z-index: 1000" data-bs-dismiss="offcanvas" aria-label="Close">
      <i class="fa fa-chevron-left pull-right text-light"></i>
    </button>
    <div class="offcanvas-body h-100">
      <div class="d-flex flex-column h-100">
        <ul class="nav nav-tabs nav-justified flex-shrink-1">
          <li class="nav-item" :class="modeName ? 'flex-grow-0' : 'flex-grow-1'">
            <a :class="activeTab === 0 || !modeName ? 'active' : ''" class="nav-link" style="cursor: pointer" v-on:click="activeTab = 0">
              <p class="text-secondary pb-0 mb-0 fw-bold"><span class="fa fa-pager"></span><br><span class="small">Configuration</span></p>
            </a>
          </li>
          <li v-if="modeName" class="nav-item flex-grow-1">
            <a :class="activeTab === 1 ? 'active' : ''" class="nav-link" style="cursor: pointer" v-on:click="activeTab = 1">
              <p class="text-secondary pb-0 mb-0 fw-bold"><span class="fa fa-box"></span><br><span class="small">Mode {{ modeName }}</span><span :class="currentMode === configMode ? 'bg-success' : 'bg-danger'" class="badge rounded-pill ms-2">{{currentMode === configMode ? 'A' :  'Ina'}}ctif</span></p>
            </a>
          </li>
        </ul>
        <Card class="px-0 mx-0 mb-2 flex-grow-1 overflow-hidden" :titleSize="5">
          <perfect-scrollbar class="h-100" v-bind:options="{ wheelPropagation: false, suppressScrollX: true }">
            <transition name="slide-fade">
              <GeneralConfiguration v-if="activeTab === 0 || !modeName"/>
            </transition>
            <transition name="slide-fade">
              <template v-if="activeTab === 1">
                <HideLeftBar v-if="configMode === 'Hide'"/>
                <QuestionLeftBar v-else-if="configMode === 'Question'"/>
                <VoteLeftBar v-else-if="configMode === 'Vote'"/>
                <BreakTheWallLeftBar v-else-if="configMode === 'BreakTheWall'"/>
                <WordCloudLeftBar v-else-if="configMode === 'WordsCloud'"/>
                <RankingLeftBar v-else-if="configMode === 'Ranking'"/>
                <LiveChatLeftBar v-else-if="configMode === 'LiveChat'"/>
                <VoteBoardLeftBar v-else-if="configMode === 'VoteBoard'"/>
                <JaugeLeftBar v-else-if="configMode === 'Jauge'"/>
              </template>
            </transition>
          </perfect-scrollbar>
        </Card>
        <div class="row">
          <div class="col-auto text-center">
            <button @click="updateConfigsOnBase" class="btn btn-success px-3 text-white fw-bold"><i class="fa fa-save pe-2 text-light"></i>Sauvegarder</button>
          </div>
          <div class="col-auto">
            <div class="dropdown">
              <button class="btn btn-secondary dropdown-toggle" type="button" id="changeModeButton" data-bs-toggle="dropdown" aria-expanded="false">
                Éditer un mode
              </button>
              <ul class="dropdown-menu" aria-labelledby="changeModeButton">
                <li><button class="dropdown-item" @click="setConfigMode('Hide')">Masqué<span v-if="currentMode === 'Hide'" class="badge rounded-pill bg-success ms-2">Actif</span></button></li>
                <li><button class="dropdown-item" @click="setConfigMode('Question')">Question / Réponse<span v-if="currentMode === 'Question'" class="badge rounded-pill bg-success ms-2">Actif</span></button></li>
                <li><button class="dropdown-item" @click="setConfigMode('Vote')">Vote<span v-if="currentMode === 'Vote'" class="badge rounded-pill bg-success ms-2">Actif</span></button></li>
                <li><button class="dropdown-item" @click="setConfigMode('BreakTheWall')">Break the Wall<span v-if="currentMode === 'BreakTheWall'" class="badge rounded-pill bg-success ms-2">Actif</span></button></li>
                <li><button class="dropdown-item" @click="setConfigMode('WordsCloud')">Nuage de mots<span v-if="currentMode === 'WordsCloud'" class="badge rounded-pill bg-success ms-2">Actif</span></button></li>
                <li><button class="dropdown-item" @click="setConfigMode('Ranking')">Classement<span v-if="currentMode === 'Ranking'" class="badge rounded-pill bg-success ms-2">Actif</span></button></li>
                <li><button class="dropdown-item" @click="setConfigMode('LiveChat')">Chat live<span v-if="currentMode === 'LiveChat'" class="badge rounded-pill bg-success ms-2">Actif</span></button></li>
                <li><button class="dropdown-item" @click="setConfigMode('VoteBoard')">Vote Board<span v-if="currentMode === 'VoteBoard'" class="badge rounded-pill bg-success ms-2">Actif</span></button></li>
                <li><button class="dropdown-item" @click="setConfigMode('Jauge')">Jauge<span v-if="currentMode === 'Jauge'" class="badge rounded-pill bg-success ms-2">Actif</span></button></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import VueNotifications from 'vue-notifications';
import { mapGetters } from 'vuex';
import Card from './utilities/Card.vue';
import LiveChatLeftBar from './Modes/LiveChat/LiveChatLeftBar.vue';
import VoteLeftBar from './Modes/Vote/VoteLeftBar.vue';
import WordCloudLeftBar from './Modes/WordCloud/WordCloudLeftBar.vue';
import RankingLeftBar from './Modes/Ranking/RankingLeftBar.vue';
import QuestionLeftBar from './Modes/Question/QuestionLeftBar.vue';
import HideLeftBar from './Modes/Hide/HideLeftBar.vue';
import BreakTheWallLeftBar from './Modes/BreakTheWall/BreakTheWallLeftBar.vue';
import VoteBoardLeftBar from './Modes/VoteBoard/VoteBoardLeftBar.vue';
import JaugeLeftBar from './Modes/Jauge/JaugeLeftBar.vue';
import GeneralConfiguration from './GeneralConfiguration.vue';

export default {
  name: 'SidePanel',
  components: {
    JaugeLeftBar,
    VoteBoardLeftBar,
    LiveChatLeftBar,
    RankingLeftBar,
    BreakTheWallLeftBar,
    WordCloudLeftBar,
    VoteLeftBar,
    GeneralConfiguration,
    Card,
    HideLeftBar,
    QuestionLeftBar,
  },
  data() {
    return {
      activeTab: 1,
    };
  },
  computed: {
    ...mapGetters([
      'currentMode',
      'editedMode',
      'hideConfig',
      'breakTheWallConfig',
      'questionConfig',
      'rankingConfig',
      'voteConfig',
      'wordCloudConfig',
      'liveChatConfig',
      'voteBoardConfig',
      'jaugeConfig',
      'generalConfig',
    ]),
    modeName()
    {
      switch (this.configMode)
      {
        case 'Hide': return 'Masqué';
        case 'Question': return 'Question / Réponse';
        case 'Vote': return 'Vote';
        case 'BreakTheWall': return 'Break the Wall';
        case 'WordsCloud': return 'Nuage de mots';
        case 'Ranking': return 'Classement';
        case 'LiveChat': return 'Chat live';
        case 'VoteBoard': return 'Vote Board';
        case 'Jauge': return 'Jauge';
        default: return undefined;
      }
    },
    configMode()
    {
      if (this.editedMode)
        return this.editedMode;
      if (this.currentMode)
        return this.currentMode;
      return '';
    },
  },
  methods: {
    updateConfigsOnBase() {
      this.$store.dispatch('updateConfig', { endpoint: 'screenConfig/HideConfig', object: this.hideConfig });
      this.$store.dispatch('updateConfig', { endpoint: 'screenConfig/BreakTheWallConfig', object: this.breakTheWallConfig });
      this.$store.dispatch('updateConfig', { endpoint: 'screenConfig/QuestionConfig', object: this.questionConfig });
      this.$store.dispatch('updateConfig', { endpoint: 'screenConfig/RankingConfig', object: this.rankingConfig });
      this.$store.dispatch('updateConfig', { endpoint: 'screenConfig/VoteConfig', object: this.voteConfig });
      this.$store.dispatch('updateConfig', { endpoint: 'screenConfig/WordCloudConfig', object: this.wordCloudConfig });
      this.$store.dispatch('updateConfig', { endpoint: 'screenConfig/LiveChatConfig', object: this.liveChatConfig });
      this.$store.dispatch('updateConfig', { endpoint: 'screenConfig/VoteBoardConfig', object: this.voteBoardConfig });
      this.$store.dispatch('updateConfig', { endpoint: 'screenConfig/JaugeConfig', object: this.jaugeConfig });
      this.$store.dispatch('updateGeneralConfig', { config: this.generalConfig });
      this.saveNotif();
    },
    setConfigMode(newMode)
    {
      this.$store.commit('setEditedMode', newMode);
    },
  },
  notifications: {
    saveNotif: {
      type: VueNotifications.types.success,
      title: 'Configurations sauvegardées',
      message: 'Les configurations ont bien été sauvegardées !',
    },
  },
};

</script>

<style>

.slide-fade-enter-active {
  transition: all .2s ease;
}
.slide-fade-enter {
  transform: translateX(-100%);
}

</style>
