import firebaseBindings from './FirebaseBindings';
import firebaseActions from './FirebaseActions';
import firebaseReference, { fbInit } from './FirebaseReference';

/**
 * this table is used to associate modes with command from the remote
 * Name : the mode you want to display
 * Commands : the command associated with the mode
 */

const commandAssociationTable = [
  {
    name: 'Question',
    commands: [
      'loop-messages',
      'display-message',
    ],
  },
  {
    name: 'Hide',
    commands: [
      'hide',
    ],
  },
  {
    name: 'Vote',
    commands: [
      'display-question',
      'display-question-answers',
      'display-stats',
      'display-good-answers',
    ],
  },
  {
    name: 'Jauge',
    commands: [
      'display-question-jauge',
      'display-question-answers-jauge',
      'display-stats-jauge',
      'display-good-answers-jauge',
    ],
  },
  {
    name: 'BreakTheWall',
    commands: [
      'display-break-the-wall',
      'reset-break-the-wall',
    ],
  },
  {
    name: 'WordsCloud',
    commands: [
      'display-wordcloud',
    ],
  },
  {
    name: 'LiveChat',
    commands: [
      'display-chat',
    ],
  },
  {
    name: 'Ranking',
    commands: [
      'display-ranking',
    ],
  },
  {
    name: 'VoteBoard',
    commands: [
      'display-question-voteboard',
      'display-question-answers-voteboard',
      'display-stats-voteboard',
      'display-good-answers-voteboard',
    ],
  },
];

/**
 * @brief Store functions, mostly related to firebase
 * Most of the action will take a payload argument, it must be formmated like this :
 *  payload: {
 *   endpoint: '',    // the node name you want to bind
 *   objectName: '',  // Name of the object you want to bind, must be available in the state (optional in the action 'updateConfig')
 *   object: {},      // the bounded object (optional in the action 'bindConfig/Data')
 *  },
 */
const actions = {
  ...firebaseBindings,
  ...firebaseActions,

  /**
   * @brief
   * Load the query parameters in the URL
   *  - path : the firebase path for the interaction
   *  - tokenID : Captag TokenID
   * on success this function will dispatch the action 'connectToFirebase'
   * @param none
   * @return nothing
   */
  async loadUrlParams({ commit, dispatch, state })
  {
    const searchParams = new URLSearchParams(window.location.search);
    const claims = await fbInit(searchParams.get('tk') || '');
    if (!claims)
    {
      console.log('Error : missing parameter tokenID');
      commit('setMode', 'BadConfig');
      commit('setCommand', '');
      return;
    }

    commit('showConfig', claims.showConfig !== false);

    // Render config ( this app )
    dispatch('bindConfigWithInitialize', { endpoint: 'screenConfig/QuestionConfig', objectName: 'QuestionConfig', object: state.QuestionConfig });
    dispatch('bindConfigWithInitialize', { endpoint: 'screenConfig/WordCloudConfig', objectName: 'WordCloudConfig', object: state.WordCloudConfig });
    dispatch('bindConfigWithInitialize', { endpoint: 'screenConfig/VoteConfig', objectName: 'VoteConfig', object: state.VoteConfig });
    dispatch('bindConfigWithInitialize', { endpoint: 'screenConfig/HideConfig', objectName: 'HideConfig', object: state.HideConfig });
    dispatch('bindConfigWithInitialize', { endpoint: 'screenConfig/RankingConfig', objectName: 'RankingConfig', object: state.RankingConfig });
    dispatch('bindConfigWithInitialize', { endpoint: 'screenConfig/BreakTheWallConfig', objectName: 'BreakTheWallConfig', object: state.BreakTheWallConfig });
    dispatch('bindConfigWithInitialize', { endpoint: 'screenConfig/LiveChatConfig', objectName: 'LiveChatConfig', object: state.LiveChatConfig });
    dispatch('bindConfigWithInitialize', { endpoint: 'screen/action', objectName: 'screenAction', object: { callback: '', targetID: '', toDo: 'hide' } });
    dispatch('bindConfigWithInitialize', { endpoint: 'screenConfig/VoteBoardConfig', objectName: 'VoteBoardConfig', object: state.VoteBoardConfig });
    dispatch('bindConfigWithInitialize', { endpoint: 'screenConfig/JaugeConfig', objectName: 'JaugeConfig', object: state.JaugeConfig });

    // Datas
    dispatch('bindData', { endpoint: 'messages', objectName: 'Messages', object: state.Messages });
    dispatch('bindData', { endpoint: 'answers', objectName: 'Answers', object: state.Answers });

    if (!searchParams.has('unbindBalls'))
      dispatch('bindData', { endpoint: 'balls', objectName: 'BreakTheWallBalls', object: state.BreakTheWallBalls });

    dispatch('bindData', { endpoint: 'words', objectName: 'words', object: state.words });

    // Configs
    dispatch('bindConfig', { endpoint: 'polls', objectName: 'Polls', object: state.Polls });
    dispatch('bindConfig', { endpoint: 'screenConfig/GeneralConfig', objectName: 'GeneralConfig', object: state.GeneralConfig });
    dispatch('bindConfig', { endpoint: 'config/break_the_wall', objectName: 'BreakTheWallRemoteConfig', object: state.BreakTheWallRemoteConfig });
    dispatch('bindConfig', { endpoint: 'config', objectName: 'remoteConfiguration', object: state.remoteConfiguration });
    dispatch('bindConfig', { endpoint: 'screen/loop', objectName: 'loop', object: state.loop });
  },

  /**
   * This function Call the Captag Webservice to retrieve the last ranking
   * based on the given Animation ID and team data
   * It save the result by committing the mutation 'setLastKnownRanking'
   * @param payload must follow the next form :
   * {
   *   animID: the ranking animation ID
   *   teamData: [Optional] the ID of the data that define the team for users
   * }
   */
  async updateRanking({ dispatch, getters, commit, state }, { animID, limit, teamAverage, teamData })
  {
    let baseURL = `https://europe-west1-captag-events.cloudfunctions.net/points/rankings/${firebaseReference.eID}/${animID}?force=true&limit=${limit || 10}`;
    if (teamData) {
      baseURL += `&groupBy=${teamData}`;
      if (teamAverage) {
        baseURL += '&mean=true';
      }
    }
    const [data, err] = await firebaseReference.fetchAPI(baseURL);
    if (!data || err)
    {
      console.error(`Error while updating ranking ${err}`);
      return;
    }
    const ranking = [];
    data.datas.forEach((el) => {
      ranking.push([el.data, el.total]);
    });
    commit('setLastKnownRanking', ranking);
  },

  /**
   * This function watch the screenAction object in the state
   * On modification the function will parse the commandAssociationTable looking for the new command
   * If the command is found the mutation 'setMode' is committed with the associated mode
   * If the command is unknown the mutation 'setMode' is committed with 'BadConfig' mode
   */
  processCommand({ commit, state })
  {
    if (state.ignoreCommands)
    {
      console.log('Ignore command enabled, skipped');
      return;
    }
    let found = false;
    commandAssociationTable.forEach((mds) => {
      mds.commands.forEach((cmd) => {
        if (cmd === state.screenAction.toDo)
        {
          found = true;
          console.log(`Goto Mode ${mds.name}`);
          commit('setMode', mds.name);
        }
      });
    });
    if (!found)
      commit('setMode', 'BadConfig');
  },

  /**
   * Return the Google font list form the Google Api
   * @returns an array containing the fonts, empty upon failure
   */
  async getGoogleFontList()
  {
    try
    {
      const response = await fetch('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyARhQYV7SROMQ2fdaCaPpILJtliMajpEKU');
      if (!response.ok)
        return [];
      const content = await response.json();
      return content.items || [];
    } catch (err)
    {
      console.error(err);
      return [];
    }
  },
  /**
   * Upload a font file to captag cdn
   * @param getters
   * @param fileData the font file actual datas
   * @param fileName the file name that will be used as public_id
   * @returns the file access URL
   */
  async uploadFont({ getters }, { fileData, fileName })
  {
    const formData = new FormData();
    formData.append('', fileData, fileName);
    formData.append('apiKey', 'f2Ran1l9K1kJCAUEPWj5');
    formData.append('folder', `${firebaseReference.eID}/interactions/fonts`);

    const response = await fetch('https://europe-west1-captag-events.cloudfunctions.net/uploads/uploadFile', { method: 'POST', body: formData });
    if (!response.ok)
    {
      console.log(`Error while uploading font : ${response.message}`);
      return {};
    }
    const data = await response.json();
    if (data && data.url)
      return data.url;
    return {};
  },
  /**
   * Return the CSS for custom fonts
   * @return string containing the imports
   */
  getImportedFontCSS({ getters })
  {
    let fontsCSS = '';
    Object.values(getters.googleFonts).forEach((element) => {
      if (element.source === 'GoogleFont' && element.GoogleFontURL !== undefined && element.GoogleFontURL !== '')
        fontsCSS += `@import url(${element.GoogleFontURL});`;
      if (element.source === 'Custom' && element.fontFace !== undefined && element.fontFace !== '')
        fontsCSS += element.fontFace;
    });
    return fontsCSS;
  },
};

export default actions;
