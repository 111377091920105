/**
 * TODO DOC
 */
const VoteBoardConfig = {
  backgroundImage: 'assets/FondVoteBoard.png',
  backgroundColor: {
    rgba: {
      r: 255,
      g: 255,
      b: 255,
      a: 255,
    },
    hex: '#ffffff',
  },
  QuestionBackgroundColor: {
    rgba: {
      r: 19,
      g: 78,
      b: 111,
      a: 255,
    },
    hex: '#134E6F',
  },
  timerColor: {
    rgba: {
      r: 80,
      g: 191,
      b: 138,
      a: 255,
    },
    hex: '#FF6150',
  },
  goodAnswerColor: {
    rgba: {
      r: 0,
      g: 255,
      b: 0,
      a: 255,
    },
    hex: '#00ff00',
  },
  questionSize: 44,
  answerSize: 33,
  fontColor: {
    rgba: {
      r: 255,
      g: 255,
      b: 255,
      a: 255,
    },
    hex: '#ffffff',
  },
  fontFamily: '',
  isTimer: true,
  timerDuration: 25,
  timeAlignment: 'text-center',
};

export default VoteBoardConfig;
