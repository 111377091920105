import Vue from 'vue';
import { uuid } from 'vue-uuid';
import { vuexfireMutations } from 'vuexfire';
import ConfigMutations from './ConfigMutations';

const mutations = {
  ...vuexfireMutations,
  ...ConfigMutations,
  addColorToWordcloudConfig(state, defaultValue)
  {
    if (!state.WordCloudConfig.fontColor)
      state.WordCloudConfig.fontColor = [];
    state.WordCloudConfig.fontColor.push({ hex: defaultValue });
  },
  removeColorFromWordCloudConfig(state, index)
  {
    state.WordCloudConfig.fontColor.splice(index, 1);
  },
  addColorToVotePieChartConfig(state, defaultValue)
  {
    if (!state.VoteConfig.answersColors)
      Vue.set(state.VoteConfig, 'answersColors', []);
    state.VoteConfig.answersColors.push({ hex: defaultValue });
  },
  removeColorFromVotePieChartConfig(state, index)
  {
    state.VoteConfig.answersColors.splice(index, 1);
  },
  setPath(state, path)
  {
    path.trim();
    state.path = path;
  },
  setTokenId(state, tokenId)
  {
    tokenId.trim();
    state.tokenId = tokenId;
  },
  setFallbackTokenUrl(state, tokenUrl)
  {
    state.fallbackTokenUrl = tokenUrl;
  },
  showConfig(state, value)
  {
    state.showConfig = value;
  },
  setMode(state, mode)
  {
    state.mode = mode;
  },
  setEditedMode(state, mode)
  {
    state.editedMode = mode;
  },
  setCommand(state, cmd)
  {
    state.command = cmd;
  },
  setCaptagApiToken(state, token)
  {
    state.captagApiToken = token;
  },
  setLastKnownRanking(state, ranking)
  {
    state.lastKnownRanking = ranking;
  },
  setIgnoreCommand(state, ignore)
  {
    state.ignoreCommands = ignore;
  },
  setConfiguration(state, newConfig)
  {
    if (newConfig.WordCloudConfig !== undefined)
      state.WordCloudConfig = newConfig.WordCloudConfig;
    if (newConfig.QuestionConfig !== undefined)
      state.QuestionConfig = newConfig.QuestionConfig;
    if (newConfig.VoteConfig !== undefined)
      state.VoteConfig = newConfig.VoteConfig;
    if (newConfig.HideConfig !== undefined)
      state.HideConfig = newConfig.HideConfig;
    if (newConfig.RankingConfig !== undefined)
      state.RankingConfig = newConfig.RankingConfig;
    if (newConfig.EmojisConfig !== undefined)
      state.EmojisConfig = newConfig.EmojisConfig;
    if (newConfig.LiveChatConfig !== undefined)
      state.LiveChatConfig = newConfig.LiveChatConfig;
    if (newConfig.BreakTheWallConfig !== undefined)
      state.BreakTheWallConfig = newConfig.BreakTheWallConfig;
    if (newConfig.VoteBoardConfig !== undefined)
      state.VoteBoardConfig = newConfig.VoteBoardConfig;
    if (newConfig.GeneralConfig !== undefined)
      state.GeneralConfig = newConfig.GeneralConfig;
  },

  setGeneralConfig(state, newConfig)
  {
    state.GeneralConfig = newConfig;
  },

  setCustomCss(state, css)
  {
    if (!state.GeneralConfig)
      state.GeneralConfig = {};
    state.GeneralConfig.CustomCss = css;
  },

  /**
   *
   * @param state
   * @param color: The color code you want to add to the palette
   */
  addColorToPalette(state, { color })
  {
    console.log(`add color to palette : ${color}`);
    if (!state.GeneralConfig)
      state.GeneralConfig = {};
    if (!state.GeneralConfig.colorPalette)
      Vue.set(state.GeneralConfig, 'colorPalette', []);
    if (!state.GeneralConfig.colorPalette.includes(color))
      state.GeneralConfig.colorPalette.push(color);
  },
  /**
   * Add a Custom font to the website list.
   * An uuid will be created for this font
   * TODO rename from 'GoogleFont' to 'CustomFont' everywhere
   * @param state Vuex state (do not pass for users)
   * @param fontObject: The new font Data
   */
  addGoogleFont(state, { fontObject })
  {
    if (state.GeneralConfig.googleFonts === undefined)
      Vue.set(state.GeneralConfig, 'googleFonts', {});
    Vue.set(state.GeneralConfig.googleFonts, uuid.v4(), fontObject);
  },
  /**
   * Remove a Custom font from the website list
   * identified by he's ID
   * @param state state Vuex state (do not pass for users)
   * @param fontID: The ID of the removed font
   */
  deleteGoogleFont(state, { fontID })
  {
    Vue.delete(state.GeneralConfig.googleFonts, fontID);
  },
};

export default mutations;
