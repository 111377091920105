const deflate = require('pako/lib/deflate').deflate;
const binary = require('bops');

function ulong(t) {
  /* eslint-disable no-bitwise */
  t &= 0xffffffff;
  if (t < 0) {
    t += 0x100000000;
  }
  return t;
}

function longAlign(n) {
  /* eslint-disable no-bitwise */
  return (n + 3) & ~3;
}

function calcChecksum(buf) {
  let sum = 0;
  const nlongs = buf.length / 4;

  for (let i = 0; i < nlongs; i = i + 1)
  {
    const t = binary.readUInt32BE(buf, i * 4);
    sum = ulong(sum + t);
  }
  return sum;
}

const WOFF_OFFSET = {
  MAGIC: 0,
  FLAVOR: 4,
  SIZE: 8,
  NUM_TABLES: 12,
  RESERVED: 14,
  SFNT_SIZE: 16,
  VERSION_MAJ: 20,
  VERSION_MIN: 22,
  META_OFFSET: 24,
  META_LENGTH: 28,
  META_ORIG_LENGTH: 32,
  PRIV_OFFSET: 36,
  PRIV_LENGTH: 40,
};

const WOFF_ENTRY_OFFSET = {
  TAG: 0,
  OFFSET: 4,
  COMPR_LENGTH: 8,
  LENGTH: 12,
  CHECKSUM: 16,
};

const SFNT_OFFSET = {
  TAG: 0,
  CHECKSUM: 4,
  OFFSET: 8,
  LENGTH: 12,
};

const SFNT_ENTRY_OFFSET = {
  FLAVOR: 0,
  VERSION_MAJ: 4,
  VERSION_MIN: 6,
  CHECKSUM_ADJUSTMENT: 8,
};

const MAGIC = {
  WOFF: 0x774F4646,
  CHECKSUM_ADJUSTMENT: 0xB1B0AFBA,
};

const SIZEOF = {
  WOFF_HEADER: 44,
  WOFF_ENTRY: 20,
  SFNT_HEADER: 12,
  SFNT_TABLE_ENTRY: 16,
};

function woffAppendMetadata(src, metadata)
{
  const zdata = deflate(metadata);

  binary.writeUInt32BE(src, src.length + zdata.length, WOFF_OFFSET.SIZE);
  binary.writeUInt32BE(src, src.length, WOFF_OFFSET.META_OFFSET);
  binary.writeUInt32BE(src, zdata.length, WOFF_OFFSET.META_LENGTH);
  binary.writeUInt32BE(src, metadata.length, WOFF_OFFSET.META_ORIG_LENGTH);

  return Buffer.concat([src, zdata]);
}

export default function (arr, options) {
  arr = Buffer.from(arr.buffer, arr.byteOffset, arr.length);

  options = options || {};

  const version = {
    maj: 0,
    min: 1,
  };
  const numTables = binary.readUInt16BE(arr, 4);
  // var sfntVersion = arr.readUint32BE(0);
  let flavor = 0x10000;

  const woffHeader = Buffer.alloc(SIZEOF.WOFF_HEADER);

  binary.writeUInt32BE(woffHeader, MAGIC.WOFF, WOFF_OFFSET.MAGIC);
  binary.writeUInt16BE(woffHeader, numTables, WOFF_OFFSET.NUM_TABLES);
  binary.writeUInt16BE(woffHeader, 0, WOFF_OFFSET.RESERVED);
  binary.writeUInt32BE(woffHeader, 0, WOFF_OFFSET.SFNT_SIZE);
  binary.writeUInt32BE(woffHeader, 0, WOFF_OFFSET.META_OFFSET);
  binary.writeUInt32BE(woffHeader, 0, WOFF_OFFSET.META_LENGTH);
  binary.writeUInt32BE(woffHeader, 0, WOFF_OFFSET.META_ORIG_LENGTH);
  binary.writeUInt32BE(woffHeader, 0, WOFF_OFFSET.PRIV_OFFSET);
  binary.writeUInt32BE(woffHeader, 0, WOFF_OFFSET.PRIV_LENGTH);

  const entries = [];

  let i;
  let tableEntry;

  for (i = 0; i < numTables; i = i + 1) {
    const data = arr.subarray(SIZEOF.SFNT_HEADER + i * SIZEOF.SFNT_TABLE_ENTRY);
    tableEntry = {
      Tag: data.subarray(SFNT_OFFSET.TAG, SFNT_OFFSET.TAG + 4),
      checkSum: binary.readUInt32BE(data, SFNT_OFFSET.CHECKSUM),
      Offset: binary.readUInt32BE(data, SFNT_OFFSET.OFFSET),
      Length: binary.readUInt32BE(data, SFNT_OFFSET.LENGTH),
    };
    entries.push(tableEntry);
  }
  entries.sort((a, b) => {
    const aStr = String.fromCharCode.apply(null, a.Tag);
    const bStr = String.fromCharCode.apply(null, b.Tag);

    // eslint-disable-next-line no-nested-ternary
    return aStr === bStr ? 0 : aStr < bStr ? -1 : 1;
  });

  let offset = SIZEOF.WOFF_HEADER + numTables * SIZEOF.WOFF_ENTRY;
  let woffSize = offset;
  let sfntSize = SIZEOF.SFNT_HEADER + numTables * SIZEOF.SFNT_TABLE_ENTRY;

  const tableBuf = Buffer.alloc(numTables * SIZEOF.WOFF_ENTRY);

  for (i = 0; i < numTables; i = i + 1) {
    tableEntry = entries[i];

    if (String.fromCharCode.apply(null, tableEntry.Tag) !== 'head') {
      const algntable = arr.subarray(tableEntry.Offset, tableEntry.Offset + longAlign(tableEntry.Length));

      if (calcChecksum(algntable) !== tableEntry.checkSum) {
        console.log('Checksum error in ' + String.fromCharCode.apply(null, tableEntry.Tag));
        return [];
      }
    }

    binary.writeUInt32BE(tableBuf, binary.readUInt32BE(tableEntry.Tag, 0), i * SIZEOF.WOFF_ENTRY + WOFF_ENTRY_OFFSET.TAG);
    binary.writeUInt32BE(tableBuf, tableEntry.Length, i * SIZEOF.WOFF_ENTRY + WOFF_ENTRY_OFFSET.LENGTH);
    binary.writeUInt32BE(tableBuf, tableEntry.checkSum, i * SIZEOF.WOFF_ENTRY + WOFF_ENTRY_OFFSET.CHECKSUM);
    sfntSize += longAlign(tableEntry.Length);
  }

  let sfntOffset = SIZEOF.SFNT_HEADER + entries.length * SIZEOF.SFNT_TABLE_ENTRY;
  let csum = calcChecksum(arr.subarray(0, SIZEOF.SFNT_HEADER));

  for (i = 0; i < entries.length; i = i + 1) {
    tableEntry = entries[i];

    const b = Buffer.alloc(SIZEOF.SFNT_TABLE_ENTRY);

    binary.writeUInt32BE(b, binary.readUInt32BE(tableEntry.Tag, 0), SFNT_OFFSET.TAG);
    binary.writeUInt32BE(b, tableEntry.checkSum, SFNT_OFFSET.CHECKSUM);
    binary.writeUInt32BE(b, sfntOffset, SFNT_OFFSET.OFFSET);
    binary.writeUInt32BE(b, tableEntry.Length, SFNT_OFFSET.LENGTH);
    sfntOffset += longAlign(tableEntry.Length);
    csum += calcChecksum(b);
    csum += tableEntry.checkSum;
  }

  const checksumAdjustment = ulong(MAGIC.CHECKSUM_ADJUSTMENT - csum);

  let len;
  const woffDataChains = [];

  for (i = 0; i < entries.length; i = i + 1) {
    tableEntry = entries[i];

    const sfntData = arr.subarray(tableEntry.Offset, tableEntry.Offset + tableEntry.Length);

    if (String.fromCharCode.apply(null, tableEntry.Tag) === 'head') {
      version.maj = binary.readUInt16BE(sfntData, SFNT_ENTRY_OFFSET.VERSION_MAJ);
      version.min = binary.readUInt16BE(sfntData, SFNT_ENTRY_OFFSET.VERSION_MIN);
      flavor = binary.readUInt32BE(sfntData, SFNT_ENTRY_OFFSET.FLAVOR);
      binary.writeUInt32BE(sfntData, checksumAdjustment, SFNT_ENTRY_OFFSET.CHECKSUM_ADJUSTMENT);
    }

    const res = deflate(sfntData);

    // We should use compression only if it really save space (standard requirement).
    // Also, data should be aligned to long (with zeros?).
    const compLength = Math.min(res.length, sfntData.length);
    len = longAlign(compLength);

    const woffData = Buffer.alloc(len, 0);

    if (res.length >= sfntData.length) {
      woffData.set(sfntData);
    } else {
      woffData.set(res);
    }

    binary.writeUInt32BE(tableBuf, offset, i * SIZEOF.WOFF_ENTRY + WOFF_ENTRY_OFFSET.OFFSET);

    offset += woffData.length;
    woffSize += woffData.length;

    binary.writeUInt32BE(tableBuf, compLength, i * SIZEOF.WOFF_ENTRY + WOFF_ENTRY_OFFSET.COMPR_LENGTH);

    woffDataChains.push(woffData);
  }

  binary.writeUInt32BE(woffHeader, woffSize, WOFF_OFFSET.SIZE);
  binary.writeUInt32BE(woffHeader, sfntSize, WOFF_OFFSET.SFNT_SIZE);
  binary.writeUInt32BE(woffHeader, version.maj, WOFF_OFFSET.VERSION_MAJ);
  binary.writeUInt32BE(woffHeader, version.min, WOFF_OFFSET.VERSION_MIN);
  binary.writeUInt32BE(woffHeader, flavor, WOFF_OFFSET.FLAVOR);

  let out = Buffer.alloc(woffSize);
  let pos = 0;

  out.set(woffHeader, pos);
  pos += woffHeader.length;

  out.set(tableBuf, pos);
  pos += tableBuf.length;

  for (i = 0; i < woffDataChains.length; i = i + 1) {
    out.set(woffDataChains[i], pos);
    pos += woffDataChains[i].length;
  }

  if (options.metadata) {
    out = woffAppendMetadata(out, options.metadata);
  }

  return new Uint8Array(out.buffer, out.byteOffset, out.length);
}
