<template>
  <div>
    <label class="small">{{ label }}</label>
    <select class="form-select" v-bind:value="value" v-on:input="$emit('change', $event.target.value)">
      <option class="disabled" value="" selected>{{ dictionary.utilities.defaultFont }}</option>
      <optgroup :label="dictionary.utilities.baseFont">
        <option v-for="(ft, key) in baseFont" v-bind:key="key" v-bind:value="ft.value" >{{ft.name}}</option>
      </optgroup>
      <optgroup :label="dictionary.utilities.exoticFont">
        <option v-for="(ft, key) in serverFont" v-bind:key="key" v-bind:value="ft.value" >{{ft.name}}</option>
      </optgroup>
      <optgroup v-if="importedFonts && importedFonts !== {}" :label="dictionary.utilities.importedFonts">
        <option v-for="(ft, key) in importedFonts" v-bind:key="key" v-bind:value="`${ft.family}${ft.category ? `, ${ft.category}` : ''}`" >{{ft.family}} (Google Fonts)</option>
      </optgroup>
    </select>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { baseFont, serverFont } from '../../configurations/defaultFont.json';

export default {
  name: 'FontSelector',
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    importedFonts: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  model: {
    model: 'value',
    event: 'change',
  },
  computed: {
    ...mapGetters(['dictionary']),
    serverFont()
    {
      return serverFont;
    },
    baseFont()
    {
      return baseFont;
    },
  },
};

</script>

<style scoped>

</style>
