<template>
  <div id='app' class="h-100 container-fluid px-0" ref="interactionsWeb" >
    <div class="h-100" :style="appStyle">
      <LoadingPage v-if="currentMode === 'Loading'"/>
      <MissingConfiguration v-if="currentMode === 'BadConfig'"/>
      <Question v-if="currentMode === 'Question'"/>
      <Vote v-if="currentMode === 'Vote'"/>
      <Hide v-if="currentMode === 'Hide'"/>
      <BreakTheWall v-if="currentMode === 'BreakTheWall'"/>
      <WordsCloud v-if="currentMode === 'WordsCloud'"/>
      <Ranking v-if="currentMode === 'Ranking'"/>
      <LiveChat v-if="currentMode === 'LiveChat'"/>
      <VoteBoard v-if="currentMode === 'VoteBoard'"/>
      <Jauge v-if="currentMode === 'Jauge'"/>
    </div>
    <rights-buttons v-if="isShowConfig" id="rightButtons"></rights-buttons>
    <SidePanel v-if="isShowConfig" class="leftPanel" title="Vote Live"/>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import RightsButtons from './components/RightsButtons.vue';
import SidePanel from './components/SidePanel.vue';
import LoadingPage from './components/LoadingPage.vue';
import LiveChat from './components/Modes/LiveChat/LiveChat.vue';
import Question from './components/Modes/Question/Question.vue';
import MissingConfiguration from './components/MissingConfiguration.vue';
import BreakTheWall from './components/Modes/BreakTheWall/BreakTheWall.vue';
import Vote from './components/Modes/Vote/Vote.vue';
import Hide from './components/Modes/Hide/Hide.vue';
import WordsCloud from './components/Modes/WordCloud/WordsCloud.vue';
import Ranking from './components/Modes/Ranking/Ranking.vue';
import VoteBoard from './components/Modes/VoteBoard/VoteBoard.vue';
import Jauge from './components/Modes/Jauge/Jauge.vue';

export default {
  components: {
    VoteBoard,
    LiveChat,
    LoadingPage,
    BreakTheWall,
    MissingConfiguration,
    Question,
    RightsButtons,
    Vote,
    Hide,
    WordsCloud,
    Ranking,
    SidePanel,
    Jauge,
  },
  mounted() {
    this.$store.dispatch('loadUrlParams');
    this.$store.watch((state, getters) => getters.currentMode, (newMode) => {
      this.$store.commit('setEditedMode', newMode);
    });
    this.$store.watch((_, getters) => getters.customCss, (newCSS) => {
      this.updateCustomCSS(newCSS);
    });
    this.$store.watch((_, getters) => getters.googleFonts, () => {
      this.updateFonts();
    });
    this.updateCustomCSS(this.customCss);
    this.updateFonts();
  },
  computed: {
    ...mapGetters(['customCss', 'generalConfig', 'currentMode', 'isShowConfig']),
    appStyle()
    {
      return { fontFamily: this.generalConfig.bodyFont || undefined };
    },
  },
  methods: {
    updateFonts()
    {
      let fontsCSS = '';
      this.$store.dispatch('getImportedFontCSS').then((fonts) => {
        fontsCSS = fonts;
        const head = this.$refs.interactionsWeb;
        if (head === undefined || head === null)
          return;
        const style = document.createElement('style');
        style.id = 'fontsList';
        const oldNode = document.getElementById('fontsList');
        if (oldNode !== null && head.contains(oldNode))
          head.removeChild(oldNode);
        if (fontsCSS === '')
          return;
        head.appendChild(style);
        style.type = 'text/css';
        style.appendChild(document.createTextNode(fontsCSS));
      });
    },
    updateCustomCSS(customCSS)
    {
      const css = customCSS.replace('\n', '');
      const head = this.$refs.interactionsWeb;
      if (head === undefined || head === null)
        return;
      const style = document.createElement('style');
      style.id = 'customCSS';
      // remove the current customCSS if present
      const oldNode = document.getElementById('customCSS');
      if (oldNode !== null && head.contains(oldNode))
        head.removeChild(oldNode);
      head.appendChild(style);
      style.type = 'text/css';
      style.appendChild(document.createTextNode(css));
    },
  },
};

</script>

<style>

#CustomCSSModal .CodeMirror {
  height: 100%;
}

html, body{
  height: 100%;
}

#rightButtons {
  position: fixed;
  right: 30px;
  top: 30px;
}

.separator{
  border: none;
  border-top: 3px solid #333;
}

.leftPanel {
  position: fixed;
  top: 0px;
  left: 0px;
}

</style>
