export default {
  colorPalette: (state) => {
    if (!state.GeneralConfig || !state.GeneralConfig.colorPalette)
      return [];
    return state.GeneralConfig.colorPalette || [];
  },
  customCss: (state) => {
    if (!state.GeneralConfig || !state.GeneralConfig.CustomCss)
      return '';
    return state.GeneralConfig.CustomCss || '';
  },
  googleFonts: (state) =>
  {
    if (!state.GeneralConfig || !state.GeneralConfig.googleFonts)
      return {};
    return state.GeneralConfig.googleFonts || {};
  },
  getBorderStyle: () => (borderObj) => {
    if (!borderObj)
      borderObj = {};
    const out = {};
    const backgroundColor = borderObj.backgroundColor || {};
    if (backgroundColor)
    {
      if (backgroundColor.hex8 && backgroundColor.hex8 !== '')
        out.backgroundColor = backgroundColor.hex8;
      else if (backgroundColor.rgba)
        out.backgroundColor = `rgba(
            ${backgroundColor.rgba.r},
            ${backgroundColor.rgba.g},
            ${backgroundColor.rgba.b},
            ${backgroundColor.rgba.a}
          )`;
    }
    out.borderRadius = `${borderObj.radius || 0}px`;
    out.borderWidth = borderObj.thickness !== undefined ? `${borderObj.thickness}px` : '';
    out.borderStyle = borderObj.style || '';
    out.borderColor = borderObj.color ? borderObj.color.hex8 || '' : '';
    return out;
  },
};
