import DictionaryFrench from '../Dictionary/DictionaryFrench.json';
import DictionaryEnglish from '../Dictionary/DictionaryEnglish.json';
import ConfigGetters from './ConfigGetters';
import StyleGetters from './StyleGetters';
import DataGetters from './DataGetters';

export default {
  ...ConfigGetters,
  ...StyleGetters,
  ...DataGetters,
  currentCommand: (state) => state.screenAction.toDo,
  targetID: (state) => state.screenAction.targetID,
  currentMode: (state) => state.mode,
  editedMode: (state) => state.editedMode,
  isShowConfig: (state) => state.showConfig || false,
  isIgnoreCommand: (state) => state.ignoreCommands || false,
  dictionary: (state) => {
    if (!state.lang || state.lang === 'fr')
      return DictionaryFrench;
    if (state.lang === 'en')
      return DictionaryEnglish;
    return {};
  },
};
