/**
 * TODO DOC
 */
const JaugeConfig = {
  backgroundColor: {
    rgba: {
      r: 0,
      g: 0,
      b: 0,
      a: 255,
    },
  },
  backgroundImage: 'https://captag-interactions.web.app/assets/FondVote.png',
  fontColor: {
    rgba: {
      r: 0,
      g: 0,
      b: 0,
      a: 0,
    },
    hex: '#000000',
  },
  fontFamily: '',
  fontSize: '44',
  jaugeImage: '',
  needleImage: '',
};

export default JaugeConfig;
