<template>
  <div  id="WordCloud" class="p-5 overflow-hidden h-100 background d-flex justify-content-center align-items-center" :style="backgroundStyle">
    <vue-word-cloud :spacing="0.50" class="w-100 h-100" :words="wordsArray" :font-family="wordCloudConfig.fontFamily"/>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import VueWordCloud from 'vuewordcloud';

const Chance = require('chance');

export default {
  name: 'WordsCloud',
  components: { VueWordCloud },
  data() {
    return {
      wordsArray: [],
      timer: '',
    };
  },
  created()
  {
    this.fetchWords();
    this.timer = setInterval(this.fetchWords, 5000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  computed: {
    ...mapGetters(['wordCloudConfig', 'words', 'targetID']),
    /**
     * Background style binding
     * @return {{backgroundColor, background: string}}
     */
    backgroundStyle()
    {
      return {
        background: `url("${this.wordCloudConfig.backgroundImage}") no-repeat center center/cover fixed`,
        backgroundColor: this.wordCloudConfig.backgroundColor.hex,
      };
    },
    currentWords()
    {
      if (!this.targetID)
        return this.words;
      return this.words[this.targetID] || {};
    },
  },
  methods: {
    rotation(val) {
      switch (this.wordCloudConfig.orientation)
      {
        case 'horizontal':
          // return this.$chance.bool() ? 0.75 : 0.25;
          return 0;
        case 'diagonal':
          return this.$chance.bool() ? 0.35 : 0.85;
        case 'cross':
          // eslint-disable-next-line no-nested-ternary
          return this.$chance.bool() ? 0 : this.$chance.bool() ? 0.25 : 0.75;
        case 'allDirection':
          // eslint-disable-next-line no-nested-ternary
          return this.$chance.bool() ? 0 : (this.$chance.bool() ? this.$chance.bool() ? 0.35 : 0.85 : this.$chance.bool() ? 0.25 : 0.75);
        default:
          return 0;
      }
    },
    randomColor() {
      const pos = this.$chance.integer({ min: 0, max: this.wordCloudConfig.fontColor.length - 1 });
      return this.wordCloudConfig.fontColor[pos].hex;
    },
    fetchWords() {
      this.wordsArray.forEach(((value) => { value.weight = 0; }));
      Object.keys(this.currentWords).forEach((userID) => {
        Object.keys(this.currentWords[userID]).forEach((WdID) => {
          let found = false;
          const color = this.randomColor();
          const rotation = this.rotation();
          this.wordsArray.forEach(((value) => {
            if (decodeURIComponent(value.text.toLowerCase().trim()).replace(/&amp;/g, '&') === decodeURIComponent(this.currentWords[userID][WdID].toLowerCase().trim()).replace(/&amp;/g, '&'))
            {
              value.weight = value.weight + 1;
              if (value.weight > 10)
                value.weight = 10;
              found = true;
            }
          }));
          if (!found)
          {
            this.wordsArray.push({
              text: decodeURIComponent(this.currentWords[userID][WdID]).replace(/&amp;/g, '&'),
              weight: 1,
              color,
              rotation,
            });
          }
        });
      });
      this.wordsArray.forEach(((value) => {
        if (value.weight === 0)
        {
          const index = this.wordsArray.indexOf(value);
          if (index > -1)
          {
            this.wordsArray.splice(index, 1);
          }
        }
      }));
    },
  },
};

</script>

<style scoped>

</style>
