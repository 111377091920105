<template>
  <div>
    <div class="mb-3">
      <label>Couleur de fond</label>
      <InputColorPicker Label="Couleur du fond" :value="getConfigAttr('backgroundColor', {})" @change="setAttrValue('backgroundColor', $event)"/>
    </div>
    <div class="mb-3">
      <InputImageUpload title="Image de fond" :value="getConfigAttr('backgroundImage', '')" @change="setAttrValue('backgroundImage', $event)"/>
    </div>
    <div class="mb-3">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="toggle-fullwidth" v-model="border">
        <label class="form-check-label" for="toggle-fullwidth">Fond de la question</label>
      </div>
    </div>
    <Card v-if="border" title="Configuration du Fond" :titleSize="6">
      <BorderEditor class="mb-3" :value="getConfigAttr('borderConfig', {})" @change="setAttrValue('borderConfig', $event)"/>
    </Card>
    <div class="mb-3">
      <label>Couleur de la police</label>
      <InputColorPicker Label="Couleur de la police" :value="getConfigAttr('fontColor', {})" @change="setAttrValue('fontColor', $event)"/>
    </div>
    <div class="mb-3">
      <FontSelector label="Police" :value="getConfigAttr('fontFamily', '')" @change="setAttrValue('fontFamily', $event)" :imported-fonts="googleFonts"/>
    </div>
    <div class="mb-3">
      <label for="fontSize">Taille de la police</label>
      <input :value="getConfigAttr('fontSize', 21)" @change="setAttrValue('fontSize', parseInt($event.target.value))" id="fontSize" type="number" class="form-control" placeholder="21">
    </div>
    <div class="mb-3">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="toggle-fullwidth" v-model="authorInfo">
        <label class="form-check-label" for="toggle-fullwidth">Afficher les informations de l'auteur</label>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import InputColorPicker from '../../utilities/InputColorPicker.vue';
import InputImageUpload from '../../utilities/InputImageUpload.vue';
import FontSelector from '../../utilities/FontSelector.vue';
import BorderEditor from '../../utilities/BorderEditor.vue';
import Card from '../../utilities/Card.vue';

export default {
  name: 'QuestionConfig',
  components: {
    BorderEditor,
    FontSelector,
    InputImageUpload,
    InputColorPicker,
    Card,
  },
  computed: {
    ...mapGetters(['googleFonts', 'questionConfig']),
    border: {
      get()
      {
        return this.questionConfig.border || false;
      },
      set(newValue)
      {
        this.$store.commit('setQuestionConfig', { ...this.questionConfig, border: newValue });
      },
    },
    authorInfo: {
      get()
      {
        return this.questionConfig.author || false;
      },
      set(newValue)
      {
        this.$store.commit('setQuestionConfig', { ...this.questionConfig, author: newValue });
      },
    },
  },
  methods: {
    getConfigAttr(attrName, defaultValue)
    {
      return this.questionConfig[attrName] || defaultValue;
    },
    setAttrValue(attrName, value)
    {
      const out = { ...this.questionConfig };
      out[attrName] = value;
      this.$store.commit('setQuestionConfig', out);
    },
  },
};

</script>

<style scoped>

</style>
