<template>
  <div class="pe-3">
    <div class="mb-3">
      <label>Couleur de fond</label>
      <InputColorPicker Label="Couleur du fond" :value="getConfigAttr('backgroundColor', {})" @change="setAttrValue('backgroundColor', $event)"/>
    </div>
    <div class="mb-3">
      <InputImageUpload title="Image de fond" :value="getConfigAttr('backgroundImage', '')" @change="setAttrValue('backgroundImage', $event)"/>
    </div>
    <div class="mb-3">
      <InputImageUpload title="Image du mur" :value="getConfigAttr('foregroundImage', '')" @change="setAttrValue('foregroundImage', $event)"/>
    </div>
    <div class="row mb-3">
      <div class="col">
        <label for="ballScaleStart">Scale de depart des balles<b>(en %)</b></label>
        <input
          :value="breakTheWallConfig.ballScale.start"
          @change="$store.commit('setBreakTheWallConfig', { ...breakTheWallConfig, ballScale: { ...breakTheWallConfig.ballScale, start: parseInt($event.target.value) } })"
          id="ballScaleStart"
          type="number" min="0" max="100" class="form-control" placeholder="0">
      </div>
      <div class="col">
        <label for="ballScaleEnd">Scale d'arrivée des balles<b>(en %)</b></label>
        <input
          :value="breakTheWallConfig.ballScale.end"
          @change="$store.commit('setBreakTheWallConfig', { ...breakTheWallConfig, ballScale: { ...breakTheWallConfig.ballScale, end: parseInt($event.target.value) } })"
          id="ballScaleEnd" type="number" min="0" max="100" class="form-control" placeholder="0">
      </div>
    </div>
    <div class="mb-3">
      <label>
        Image de balle
        <br>
        <small class="small text-muted">Configurable dans la télécommande</small>
      </label>
      <div class="row">
        <div class="col"><input class="form-control" :value="breakTheWallConfig.ballUrl" disabled></div>
        <div class="col-auto  ms-0 ps-0"><a class="btn btn-success" :href="breakTheWallConfig.ballUrl" target="_blank"><i class="fas fa-link text-white"></i></a></div>
      </div>

    </div>
    <div class="row mb-3">
      <div class="col">
        <label for="gridSizeH">Hauteur de la grille</label>
        <input
          :value="breakTheWallConfig.gridSize.height"
          @change="$store.commit('setBreakTheWallConfig', { ...breakTheWallConfig, gridSize: { ...breakTheWallConfig.gridSize, height: parseInt($event.target.value) } })"
          id="gridSizeH" min="1" type="number" class="form-control" placeholder="0">
      </div>
      <div class="col">
        <label for="gridSizeW">Largeur de la grille</label>
        <input
          :value="breakTheWallConfig.gridSize.width"
          @change="$store.commit('setBreakTheWallConfig', { ...breakTheWallConfig, gridSize: { ...breakTheWallConfig.gridSize, width: parseInt($event.target.value) } })"
          id="gridSizeW" min="1" type="number" class="form-control" placeholder="0">
      </div>
    </div>
    <div class="mb-3">
      <label for="maxBalls">Nombre maximum de balles a la fois</label>
      <input :value="getConfigAttr('MaxBalls', 35)" @change="setAttrValue('MaxBalls', parseInt($event.target.value))" id="maxBalls" type="number" class="form-control" placeholder="100">
    </div>
    <div class="mb-3">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="toggle-fullwidth" :checked="getConfigAttr('tileDrop', false)" @change="setAttrValue('tileDrop', $event.target.checked)">
        <label class="form-check-label" for="toggle-fullwidth">Animation de chute des tuiles</label>
      </div>
    </div>
    <div class="mb-3">
      <label for="direction">Comportement de fin</label>
      <select class="form-control" :value="getConfigAttr('endBehavior', 'reloadFade')" @change="setAttrValue('endBehavior', $event.target.value)" id="direction">
        <option value="reloadCut" >Rechargement avec 'cut'</option>
        <option value="reloadFade" >Rechargement avec 'fade'</option>
        <option value="nothing" >Ne rien faire</option>
      </select>
    </div>
    <div v-if="getConfigAttr('endBehavior', 'reloadFade') !== 'nothing'" class="form-group">
      <label for="reloadTime">Temps avant le rechagement</label>
      <input :value="getConfigAttr('timeBeforeFade', 35)" @change="setAttrValue('timeBeforeFade', parseInt($event.target.value))" id="reloadTime" type="number" class="form-control" placeholder="100">
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import InputImageUpload from '../../utilities/InputImageUpload.vue';
import InputColorPicker from '../../utilities/InputColorPicker.vue';

export default {
  name: 'BreakTheWallLeftBar',
  components: {
    InputImageUpload,
    InputColorPicker,
  },
  computed: {
    ...mapGetters(['breakTheWallConfig']),
  },
  methods: {
    getConfigAttr(attrName, defaultValue)
    {
      return this.breakTheWallConfig[attrName] || defaultValue;
    },
    setAttrValue(attrName, value)
    {
      const out = { ...this.breakTheWallConfig };
      out[attrName] = value;
      this.$store.commit('setBreakTheWallConfig', out);
    },
  },
};

</script>

<style scoped>

</style>
