<template>
  <context-menu :id="`${this.contextId}-confirmation-context-menu`" :ref="`${this.contextId}-confirmation-ctxMenu`" style="width: 400px;">
    <div class="m-2 row text-center">
      <div class="col-12 mb-3">
        <div v-html="introMessage"></div>
      </div>
      <div class="col-md-6">
        <button class="btn btn-danger btn-raised text-white" @click="$emit('deni')" v-html="deniButtonContent || dictionary.cancel"/>
      </div>
      <div class="col-md-6">
        <button class="btn btn-success btn-raised text-white" @click="$emit('confirm')" v-html="confirmButtonContent || dictionary.confirm"/>
      </div>
    </div>
  </context-menu>
</template>

<script>

import contextMenu from 'vue-context-menu';
import { mapGetters } from 'vuex';

export default {
  name: 'ConfirmationContext',
  components: {
    contextMenu,
  },
  props: {
    introMessage: String,
    id: {
      required: false,
      type: String,
      default: 'unset',
    },
    confirmButtonContent: {
      required: false,
      type: String,
      default: undefined,
    },
    deniButtonContent: {
      required: false,
      type: String,
      default: undefined,
    },
  },
  computed: {
    ...mapGetters(['dictionary']),
  },
  data()
  {
    return {
      contextId: '',
    };
  },
  created()
  {
    this.contextId = (this.id === 'unset') ? this.contextId = this.getNewId() : this.id;
  },
  methods: {
    getNewId()
    {
      return this.$chance.string({
        length: 5,
        casing: 'upper',
        alpha: true,
        numeric: true,
      });
    },
    openContext()
    {
      this.$refs[`${this.contextId}-confirmation-ctxMenu`].open();
    },
  },
};

</script>

<style scoped>

</style>
