<template>
  <div >
    <div class="progress h-100" style="position: relative;">
      <div style="position: absolute; top: 0; left: 0;" class="progress-bar h-100" role="progressbar" :style="progressBarStyle" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
      </div>
      <div style="position: absolute; top: 0; left: 0;" class="w-100 h-100">
        <div class="row h-100 d-flex justify-content-center align-items-center">
          <div class="col-4 text-center h-100">
            <img class="h-100 p-3" :src="this.pictoSrc">
          </div>
          <div class="col-8 text-left px-5">
            <h4 v-html="this.text" :style="textStyle"></h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'VoteBoardBar',
  props: {
    progress: Number,
    text: String,
    color: String,
    pictoSrc: String,
  },
  computed: {
    ...mapGetters(['voteBoardConfig']),
    textStyle() {
      return {
        fontSize: this.answersFontSize,
        color: this.voteBoardConfig.fontColor.hex,
        fontFamily: this.voteBoardConfig.fontFamily,
      };
    },
    progressBarStyle() {
      return {
        width: `${this.progress}%`,
      };
    },

    /**
     * Global font size computing
     * if the input is only composed of number, the computed will return the value + px
     * and return the full input otherwise
     * @return {string}
     */
    answersFontSize()
    {
      if (/^\d+$/.test(this.voteBoardConfig.answerSize))
        return `${this.voteBoardConfig.answerSize}px`;
      return this.voteBoardConfig.answerSize;
    },
  },
};

</script>

<style scoped>

.progress {
  border-radius: 20px;
}

</style>
