<template>
  <Doughnut
    v-if="isShowBars"
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="'doughnut-chart'"
    :dataset-id-key="'label'"
    :plugins="[]"
    :css-classes="''"
    :styles="{}"
    :width="400"
    :height="400"
  />
</template>

<script>

import { mapGetters } from 'vuex';
import { Doughnut } from 'vue-chartjs/legacy';
import {
  Chart as ChartJS,
  Title,
  Legend,
  ArcElement,
  CategoryScale,
  LegendOptions,
} from 'chart.js';

ChartJS.register(Legend, ArcElement, CategoryScale);

export default {
  name: 'VotePieChartRender',
  components: {
    Doughnut,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    choices: {
      type: Array,
      required: true,
    },
    answers: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['voteConfig', 'currentCommand']),
    /**
     * Return true if the commmand ask to show the bars
     * @return {boolean}
     */
    isShowBars()
    {
      return this.currentCommand !== 'display-question-answers';
    },
    idShowGoodAnswer()
    {
      return this.currentCommand === 'display-good-answers';
    },
    chartOptions()
    {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: this.voteConfig.pieChartDisplayLegend,
            position: this.voteConfig.pieChartLegendPosition || 'left',
            labels: { font: { size: 22 } },
          },
        },
      };
    },
    chartData()
    {
      const out = {
        labels: [],
        datasets: [
          {
            label: 'Default Data',
            backgroundColor: [],
            data: [],
          },
        ],
      };
      this.choices.forEach((val) => {
        out.labels.push(val.text);
        out.datasets[0].data.push(this.getAnswerCountForAnswer(val.id));
      });
      const goodAnsColor = this.voteConfig.barGoodAnswerColor ? this.voteConfig.barGoodAnswerColor.hex || '#FFFFFF' : '#FFFFFF';
      for (let i = 0; i < 30; i = i + 1)
      {
        let color = this.$chance.color({ format: 'hex' });
        if (this.voteConfig.answersColors.length > 0)
        {
          const col = this.voteConfig.answersColors[i % this.voteConfig.answersColors.length];
          color = col.hex || color;
        }
        if (this.idShowGoodAnswer)
        {
          if (this.choices && this.choices[i] && this.isGoodAnswer(this.choices[i].id))
            out.datasets[0].backgroundColor.push(goodAnsColor);
          else
            out.datasets[0].backgroundColor.push('#444444');
        }
        else
          out.datasets[0].backgroundColor.push(color);
      }
      return out;
    },
  },
  methods: {
    /**
     * Return the count of answer for the current Question and the given answer
     * @param ansKey the answer Identifier
     * @return {number}
     */
    getAnswerCountForAnswer(ansKey)
    {
      let count = 0;
      Object.values(this.answers).forEach((value) => {
        value.forEach((rep) => {
          count += (rep === ansKey) ? 1 : 0;
        });
      });
      return count;
    },
    /**
     * Return true if the answers identified by ansKey is in the good array of the current Vote
     * aka : true if this is the right answer to the question
     * @param ansKey the answer Identifier
     * @return {boolean}
     */
    isGoodAnswer(ansKey)
    {
      return (this.question.goods) ? this.question.goods.includes(ansKey) : false;
    },
  },
};

</script>

<style scoped>

</style>
