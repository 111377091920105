/**
 * TODO DOC
 */
const VoteConfig = {
  backgroundColor: {
    rgba: {
      r: 0,
      g: 0,
      b: 0,
      a: 255,
    },
  },
  backgroundImage: 'https://captag-interactions.web.app/assets/FondVote.png',
  border: true,
  borderColor: {
    rgba: {
      r: 255,
      g: 167,
      b: 37,
      a: 1,
    },
    hex: '#FAA725',
  },
  barColor: {
    rgba: {
      r: 255,
      g: 255,
      b: 255,
      a: 1,
    },
    hex: '#ffffff',
  },
  backgroundBarColor: {
    rgba: {
      r: 0,
      g: 0,
      b: 0,
      a: 0,
    },
    hex: '#000000',
  },
  barGoodAnswerColor: {
    rgba: {
      r: 27,
      g: 224,
      b: 47,
      a: 1,
    },
    hex: '#1BE02F',
  },
  fontColor: {
    rgba: {
      r: 0,
      g: 0,
      b: 0,
      a: 0,
    },
    hex: '#000000',
  },
  fontFamily: '',
  fontSize: '44',
  valueStyle: 'percent',
  isTimer: false,
  orderAnimation: true,
  timerDuration: 25,
  timeAlignment: 'text-center',
  timerColor: {
    rgba: {
      r: 80,
      g: 191,
      b: 138,
      a: 255,
    },
    hex: '#FF6150',
  },
  showIndex: true,
  pieChartLegendPosition: 'left',
  renderType: 'bars',
  answersColors: [
    { hex: '#D61616' },
    { hex: '#1B00E4' },
    { hex: '#16D48D' },
  ],
};

export default VoteConfig;
