import WordCloudConfig from '../configurations/WordCloudConfig';
import QuestionConfig from '../configurations/QuestionConfig';
import VoteConfig from '../configurations/VoteConfig';
import HideConfig from '../configurations/HideConfig';
import RankingConfig from '../configurations/RankingConfig';
import BreakTheWallConfig from '../configurations/BreakTheWallConfig';
import LiveChatConfig from '../configurations/LiveChatConfig';
import VoteBoardConfig from '../configurations/VoteBoardConfig';
import JaugeConfig from '../configurations/JaugeConfig';

const state = {
  showConfig: false,
  // Actual display mode
  mode: 'Loading',
  editedMode: '',
  colorPalette: [],
  // screen action Object
  screenAction: {
    callback: '',
    targetID: '',
    toDo: '',
  },
  loop: {},
  // remote configuration
  remoteConfiguration: {},
  // The associated agenda
  Agenda: {
    name: '',
  },
  words: {},
  // Chat messages
  Messages: {},
  // Votes
  Polls: {},
  // Votes answers
  Answers: {},
  // All peoples in base ranked by theirs points
  lastKnownRanking: [],
  // Word Cloud Configuration Object
  WordCloudConfig,
  // Question Configuration Object
  QuestionConfig,
  // Vote Configuration Object
  VoteConfig,
  // Hide configuration Object
  HideConfig,
  // Hide configuration Object
  RankingConfig,
  // Live chat configuration Object
  LiveChatConfig,
  // Break the wall configuration Object
  BreakTheWallConfig,
  // Vote Board Config
  VoteBoardConfig,
  // Jauge style Vote Config
  JaugeConfig,
  // Break the wall Remote configuration, used to gather the ball texture URL
  BreakTheWallRemoteConfig: {
    ballUrl: '',
  },
  // Ball throwed by the players
  BreakTheWallBalls: {},
  // User custom style
  GeneralConfig: {},
  // Reaction (Mood board) lists {userID: {reactionID: number}}
  Reactions: {},
  ignoreCommands: false,
  Gatherings: {},
};

export default state;
