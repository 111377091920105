<template>
  <div class="pe-3">
    <div class="mb-3">
      <label>Couleur de fond</label>
      <InputColorPicker Label="Couleur du fond" :value="getConfigAttr('backgroundColor', {})" @change="setAttrValue('backgroundColor', $event)"/>
    </div>
    <div class="mb-3">
      <InputImageUpload title="Image de fond" :value="getConfigAttr('backgroundImage', '')" @change="setAttrValue('backgroundImage', $event)"/>
    </div>
    <div class="mb-3">
      <FontSelector label="Police" :value="getConfigAttr('fontFamily', '')" @change="setAttrValue('fontFamily', $event)" :imported-fonts="googleFonts"/>
    </div>
    <div class="mb-3">
      <label>Couleur de la police</label>
      <InputColorPicker Label="Couleur de la police" :value="getConfigAttr('fontColorText', {})" @change="setAttrValue('fontColorText', $event)"/>
    </div>
    <div class="mb-3">
      <label for="direction">Alignement du chat</label>
      <select class="form-control" :value="getConfigAttr('alignment', 'text-center')" @change="setAttrValue('alignment', $event.target.value)" id="direction">
        <option value="text-start" >Gauche</option>
        <option value="text-center" >Centré</option>
        <option value="text-end" >Droite</option>
      </select>
    </div>
    <div class="mb-3">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="toggle-fullwidth" v-model="onlyVisible">
        <label class="form-check-label" for="toggle-fullwidth">Uniquement les visibles</label>
      </div>
    </div>
    <div class="mb-3">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="toggle-fullwidth" v-model="onlyFavorite">
        <label class="form-check-label" for="toggle-fullwidth">Uniquement les favoris</label>
      </div>
    </div>
    <div class="mb-3">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="toggle-fullwidth" v-model="onlySelected">
        <label class="form-check-label" for="toggle-fullwidth">Uniquement les sélectionnés</label>
      </div>
    </div>
    <div class="mb-3">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="toggle-fullwidth" v-model="displayAuthor">
        <label class="form-check-label" for="toggle-fullwidth">Afficher les informations de l'auteur</label>
      </div>
    </div>
    <Card v-if="displayAuthor" title="Auteur" :titleSize="6" class="mb-3">
      <div class="mb-3">
        <label for="authorFontSize">Taille du textes de l'auteur</label>
        <input :value="getConfigAttr('fontSizeAuthor', 21)" @change="setAttrValue('fontSizeAuthor', parseInt($event.target.value))" id="authorFontSize" type="number" class="form-control" placeholder="21">
      </div>
      <div class="mb-3">
        <label>Couleur de la police</label>
        <InputColorPicker Label="Couleur de la police" :value="getConfigAttr('fontColorAuthor', {})" @change="setAttrValue('fontColorAuthor', $event)"/>
      </div>
    </Card>
    <Card title="Zone d'affichage" :title-size="6" class="mb-3">
      <template>
        <div class="mb-3">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="toggle-fullwidth" v-model="displayZone">
            <label class="form-check-label" for="toggle-fullwidth">Afficher la zone du chat<br><span class="small text-muted"> (pour vos réglages)</span></label>
          </div>
        </div>
        <div class="row">
          <div class="col mb-3">
            <label class="small">Hauteur de la zone<i class="text-danger fa fas fa-arrows-alt-v ms-2"></i></label>
            <div class="input-group mb-3">
              <input
                min="0"
                placeholder="Inherit"
                type="number"
                class="form-control"
                aria-label="Font size"
                :value="getZoneAttr('height', 100)" @change="setZoneAttrValue('height', parseInt($event.target.value))">
              <button class="btn btn-outline-secondary disabled" type="button" data-bs-toggle="dropdown" aria-expanded="false">%</button>
            </div>
          </div>
          <div class="col mb-3">
            <label class="small">Largeur de la zone<i class="text-success fa fas fa-arrows-alt-h ms-2"></i></label>
            <div class="input-group mb-3">
              <input min="0" placeholder="Inherit" type="number" class="form-control" aria-label="Font size"
                     :value="getZoneAttr('width', 100)" @change="setZoneAttrValue('width', parseInt($event.target.value))">
              <button class="btn btn-outline-secondary disabled" type="button" data-bs-toggle="dropdown" aria-expanded="false">%</button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col mb-3">
            <label class="small">Position de la zone<i class="text-danger fa fas fa-arrow-right ms-2"></i></label>
            <div class="input-group mb-3">
              <input min="0" placeholder="Inherit" type="number" class="form-control" aria-label="Font size"
                     :value="getZoneAttr('x', 0)" @change="setZoneAttrValue('x', parseInt($event.target.value))">
              <button class="btn btn-outline-secondary disabled" type="button" data-bs-toggle="dropdown" aria-expanded="false">%</button>
            </div>
          </div>
          <div class="col mb-3">
            <label class="small">Position de la zone<i class="text-success fa fas fa-arrow-down ms-2"></i></label>
            <div class="input-group mb-3">
              <input min="0" placeholder="Inherit" type="number" class="form-control" aria-label="Font size"
                     :value="getZoneAttr('y', 0)" @change="setZoneAttrValue('y', parseInt($event.target.value))">
              <button class="btn btn-outline-secondary disabled" type="button" data-bs-toggle="dropdown" aria-expanded="false">%</button>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <label for="maxEmojis">Nombre de messages maximum</label>
          <input :value="getConfigAttr('maxMessages', 21)" @change="setAttrValue('maxMessages', parseInt($event.target.value))" id="maxEmojis" type="number" class="form-control" placeholder="35">
        </div>
      </template>
    </Card>
    <div class="mb-3">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="toggle-fullwidth" v-model="messageBackground">
        <label class="form-check-label" for="toggle-fullwidth">Fond sous les messages</label>
      </div>
    </div>
    <BorderEditor v-if="messageBackground"  class="mb-3" label="Parametres du fond" v-model="BorderConfig"/>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import InputColorPicker from '../../utilities/InputColorPicker.vue';
import InputImageUpload from '../../utilities/InputImageUpload.vue';
import FontSelector from '../../utilities/FontSelector.vue';
import Card from '../../utilities/Card.vue';
import BorderEditor from '../../utilities/BorderEditor.vue';

export default {
  name: 'LiveChatLeftBar',
  components: {
    InputImageUpload,
    InputColorPicker,
    FontSelector,
    Card,
    BorderEditor,
  },
  computed: {
    ...mapGetters(['googleFonts', 'liveChatConfig']),
    BorderConfig: {
      get()
      {
        return this.liveChatConfig.border || {};
      },
      set(newConfig)
      {
        this.$store.commit('setLiveChatConfig', { ...this.liveChatConfig, border: newConfig });
      },
    },
    onlyVisible: {
      get()
      {
        return this.liveChatConfig.onlyVisible || false;
      },
      set(newValue)
      {
        this.$store.commit('setLiveChatConfig', { ...this.liveChatConfig, onlyVisible: newValue });
      },
    },
    onlyFavorite: {
      get()
      {
        return this.liveChatConfig.onlyFavorite || false;
      },
      set(newValue)
      {
        this.$store.commit('setLiveChatConfig', { ...this.liveChatConfig, onlyFavorite: newValue });
      },
    },
    onlySelected: {
      get()
      {
        return this.liveChatConfig.onlySelected || false;
      },
      set(newValue)
      {
        this.$store.commit('setLiveChatConfig', { ...this.liveChatConfig, onlySelected: newValue });
      },
    },
    displayAuthor: {
      get()
      {
        return this.liveChatConfig.displayAuthor || false;
      },
      set(newValue)
      {
        this.$store.commit('setLiveChatConfig', { ...this.liveChatConfig, displayAuthor: newValue });
      },
    },
    displayZone: {
      get()
      {
        return this.liveChatConfig.displayZone || false;
      },
      set(newValue)
      {
        this.$store.commit('setLiveChatConfig', { ...this.liveChatConfig, displayZone: newValue });
      },
    },
    messageBackground: {
      get()
      {
        return this.liveChatConfig.messageBackground || false;
      },
      set(newValue)
      {
        this.$store.commit('setLiveChatConfig', { ...this.liveChatConfig, messageBackground: newValue });
      },
    },
  },
  methods: {
    getZoneAttr(attrName, defaultValue)
    {
      return this.liveChatConfig.zone[attrName] || defaultValue;
    },
    setZoneAttrValue(attrName, value)
    {
      const out = { ...this.liveChatConfig };
      out.zone[attrName] = value;
      this.$store.commit('setLiveChatConfig', out);
    },
    getConfigAttr(attrName, defaultValue)
    {
      return this.liveChatConfig[attrName] || defaultValue;
    },
    setAttrValue(attrName, value)
    {
      const out = { ...this.liveChatConfig };
      out[attrName] = value;
      this.$store.commit('setLiveChatConfig', out);
    },
  },
};

</script>

<style scoped>

</style>
