<template>
  <div class="h-100 d-flex justify-content-center align-items-center align-middle">
    <div class="row">
      <div class="col-12 text-center">
        <h1>Configuration manquante ou invalide</h1>
      </div>
    </div>
  </div>
</template>

<script>

export default
{
  name: 'MissingConfiguration',
};

</script>

<style scoped>

</style>
