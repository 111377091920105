<template>
  <div class="row h-100">
    <div class="col-12 h-100">
      <div style="height: 7%" class="row">
        <div class="col-12">
          <button v-on:click="$emit('cancel')" class="btn btn-danger btn-raised text-white"><i class="fas fa-arrow-left me-3"></i>{{ dictionary.back }}</button>
        </div>
      </div>
      <div style="height: 17%" class="row">
        <div class="col-12" >
          <div class="row">
            <div class="col-auto">
              <h5 class="text-secondary"><i class="fas fa-heading me-3"></i>{{ dictionary.fontModal.fontInfos }}</h5>
            </div>
            <div class="col">
              <hr>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="form-floating">
            <input v-model="fontName" type="text" class="form-control" id="brandTitleInput" placeholder="Font name">
            <label for="brandTitleInput">{{ dictionary.fontModal.fontFamily }}</label>
          </div>
        </div>
      </div>
      <div style="height: 5%" class="row">
        <div class="col-12" >
          <div class="row">
            <div class="col-auto">
              <h5 class="text-secondary"><i class="far fa-file-alt me-3"></i>{{ dictionary.fontModal.addFonts }}</h5>
            </div>
            <div class="col">
              <hr>
            </div>
          </div>
        </div>
      </div>
      <perfect-scrollbar class="row justify-content-center" style="max-height: 58%" v-bind:options="{ wheelPropagation: false }" :suppressScrollX="true">
        <div @drop.prevent="onFileDrop"
             @dragenter.prevent="dropStart = true"
             @dragleave.prevent="dropStart = false"
             @dragover.prevent
             class="col-3 mb-3 border-secondary">
          <div class="dropZone w-100 h-100 d-flex align-items-center">
            <button v-if="!dropStart" class="btn btn-outline-secondary btn-raised mx-auto" v-on:click="readFileButtonClick"><i class="fas fa-plus-circle me-3"></i>{{ dictionary.fontModal.addFont }}</button>
            <h4 v-else class="text-primary mx-auto">{{ dictionary.fontModal.addFilesDragText }}</h4>
          </div>
        </div>
        <div class="col-3 text-center mb-3" v-for="(file, fileIdx) in uploadedFiles" v-bind:key="fileIdx">
          <card class="h-100 border-secondary">
            <template v-slot:header-center>
              <h5 class="text-white"><i class="fas fa-font me-3"></i>{{ file.name }}</h5>
            </template>
            <div class="row h-100">
              <div v-if="file.state === 'loading'" class="col-5 text-center justify-content-center">
                <PixelSpinner
                    :animation-duration="1500"
                    :size="50"
                    color="#000000"
                    class="w-100"
                />
              </div>
              <div v-if="file.state === 'loading'" class="col text-start">
                <p class="mt-3">{{ dictionary.uploadLoading }}</p>
              </div>
              <div v-else class="col-12 h-100 text-center justify-content-center d-flex align-items-center">
                <div class="btn-group w-100" role="group" aria-label="Basic example">
                  <a :href="file.url" target="_blank" v-tooltip.top="`${dictionary.fontModal.downloadFile}`" class="px-3 w-100 btn btn-success text-white"><i class="fas fa-download"></i></a>
                  <button v-on:click="copyLink(file.url)" class="px-3 w-100 btn btn-info text-white" v-tooltip.top="`${dictionary.fontModal.copyFileLink}`"><i class="far fa-copy"></i></button>
                  <button v-on:click="removeFile(fileIdx)" class="px-3 w-100 btn btn-danger text-white" v-tooltip.top="`${dictionary.fontModal.deleteFile}`"><i class="fas fa-trash"></i></button>
                  <button v-if="file.name.includes('.ttf')" v-on:click="createWOFF(file)" class="px-3 w-100 btn btn-primary text-white" v-tooltip.top="`${dictionary.fontModal.generateWOFF}`"><i class="fas fa-cogs "></i></button>
                </div>
              </div>
            </div>
          </card>
        </div>
      </perfect-scrollbar>
      <div class="row d-flex align-items-center justify-content-center" style="height: 13%">
        <div class="col-4 text-center" v-tooltip.top="(uploadedFiles.length >= 1 && fontName !== '' && isAllFontLoaded) ? `${dictionary.fontModal.addFontToWeb}` : `${dictionary.fontModal.tellNameAndAdd}`"  >
          <button v-on:click="submitFont" v-bind:class="(uploadedFiles.length >= 1 && fontName !== '' && isAllFontLoaded) ? 'btn-success text-white' : 'disabled btn-outline-danger text-danger'" class="w-100 btn ">{{ dictionary.fontModal.generateFont }}</button>
        </div>
      </div>
    </div>
    <input multiple accept=".otf, .ttf, .woff, .woff2" ref="readFileHiddenInput" type="file" v-on:change="onFileSelected" style="visibility: hidden">
    <input readonly id="copyUrlInput">
  </div>
</template>

<script>

import VueNotifications from 'vue-notifications';
import { PixelSpinner } from 'epic-spinners';
import { mapGetters } from 'vuex';
import ttf2woff from './ttf2woff';
import Card from '../../utilities/Card.vue';

const fs = require('fs');

export default {
  name: 'FontFromFile',
  components: {
    Card,
    PixelSpinner,
  },
  data() {
    return {
      uploadedFiles: [],
      dropStart: false,
      fontName: '',
    };
  },
  computed: {
    ...mapGetters(['dictionary']),
    isAllFontLoaded()
    {
      let out = true;
      this.uploadedFiles.forEach((file) => {
        if (file.state !== 'ready')
          out = false;
      });
      return out;
    },
  },
  methods: {
    copyLink(url)
    {
      const copyText = document.getElementById('copyUrlInput');
      copyText.value = url;
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand('copy');
      this.linkCopied();
    },
    removeFile(fileIdx)
    {
      this.uploadedFiles.splice(fileIdx, 1);
    },
    createWOFF(file)
    {
      const name = `${file.name.replace('.ttf', '')}_${this.$chance.string({
        length: 5,
        casing: 'upper',
        alpha: true,
        numeric: true,
      })}.woff`;
      const newFile = {
        state: 'loading',
        url: undefined,
        name,
        fileData: undefined,
      };
      this.uploadedFiles.push(newFile);
      const woffData = ttf2woff(new Uint8Array(file.fileData));
      newFile.fileData = woffData;
      this.$store.dispatch('uploadFont', {
        fileName: name,
        fileData: new Blob([woffData], { type: 'font/woff2' }),
      }).then((asset) => {
        if (asset !== undefined)
        {
          newFile.state = 'ready';
          newFile.url = asset;
          this.woffGenerated();
        }
        else
          newFile.state = 'error';
      });
    },
    readFileButtonClick() {
      this.$refs.readFileHiddenInput.click();
    },
    onFileDrop(ev)
    {
      const files = [];
      for (let idx = 0; idx < ev.dataTransfer.items.length; idx = idx + 1)
      {
        if (ev.dataTransfer.items[idx].kind === 'file')
          files.push(ev.dataTransfer.items[idx].getAsFile());
      }
      this.readFiles(files);
      this.dropStart = false;
    },
    onFileSelected(ev)
    {
      this.readFiles(ev.target.files);
    },
    readFiles(files)
    {
      Array.from(files).forEach((file) => {
        const newFile = {
          state: 'loading',
          url: undefined,
          name: file.name,
          fileData: undefined,
        };
        this.uploadedFiles.push(newFile);
        const reader = new FileReader();
        reader.onload = (e) => {
          newFile.fileData = e.target.result;
          this.$store.dispatch('uploadFont', {
            fileName: file.name,
            fileData: new Blob([e.target.result], { type: 'application/octet-stream' }),
          }).then((asset) => {
            if (asset !== undefined)
            {
              newFile.state = 'ready';
              newFile.url = asset;
              this.uploadSuccess();
            }
            else
              newFile.state = 'error';
          });
        };
        reader.readAsArrayBuffer(file);
      });
    },
    generateFontFace()
    {
      let out = `@font-face { font-family: "${this.fontName}";`;
      if (this.uploadedFiles.length >= 0)
        out += 'src: ';
      this.uploadedFiles.forEach((file) => {
        const ext = file.name.match(/\.(.*)$/)[0].replace(/^\./, '');
        out += `url("${file.url}") format("${ext}"),`;
      });
      if (this.uploadedFiles.length >= 0)
        out = out.replace(new RegExp(/,$/), ';');
      out += '}';
      return out;
    },
    submitFont()
    {
      this.fontName = this.fontName.trim();
      const out = {};
      out.source = 'Custom';
      out.fontFace = this.generateFontFace();
      out.family = this.fontName;
      this.$store.commit('addGoogleFont', { fontObject: out, dbSync: true });
      this.fontSaved();
      this.$emit('cancel');
    },
  },
  notifications: {
    uploadSuccess: {
      type: VueNotifications.types.success,
      title: 'Fichier ajouté !',
      message: 'Fichier uploadé avec succès.',
    },
    linkCopied: {
      type: VueNotifications.types.success,
      title: 'Lien copié !',
      message: 'Lien du fichier copié dans votre presse papier.',
    },
    woffGenerated: {
      type: VueNotifications.types.success,
      title: 'Fichier généré !',
      message: 'Le fichier <b>WOFF</b> a été généré avec succès.',
    },
    fontSaved: {
      type: VueNotifications.types.success,
      title: 'Police ajouté !',
      message: 'La police a été ajouté avec succès.',
    },
  },
};

</script>

<style>

.dropZone {
  border: 1px dashed;
  min-height: 120px;
  border-radius: 0.25rem;
}

.ps__rail-y:hover {
  background-color: unset!important;
}

</style>
