/**
 * TODO DOC
 */
const LiveChatConfig = {
  onlyFavorite: false,
  onlyVisible: true,
  onlySelected: false,
  // onlyFans ?
  backgroundImage: 'https://captag-interactions.web.app/assets/FondChat.png',
  backgroundColor: {
    rgba: {
      r: 255,
      g: 255,
      b: 255,
      a: 1,
    },
    hex: '#ffffff',
  },
  displayZone: false,
  zone: {
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  },
  maxMessages: 30,
  fontFamily: '',
  displayAuthor: true,
  displayAvatar: true,
  fontSizeAuthor: 20,
  fontSizeText: 28,
  fontColorText: {
    rgba: {
      r: 0,
      g: 0,
      b: 0,
      a: 1,
    },
    hex: '#000000',
  },
  fontColorAuthor: {
    rgba: {
      r: 255,
      g: 255,
      b: 255,
      a: 1,
    },
    hex: '#ffffff',
  },
  messageBackground: true,
  messageBackgroundRadius: 7,
  messageBackgroundColor: {
    rgba: {
      r: 250,
      g: 167,
      b: 37,
      a: 1,
    },
    hex: '#FAA725',
  },
  alignment: 'left',
};

export default LiveChatConfig;
