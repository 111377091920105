<template>
  <div class="pe-3">
    <Card class="mb-3" title="Fond" :title-size="6">
      <div class="mb-3">
        <label>Couleur de fond</label>
        <InputColorPicker Label="Couleur du fond" :value="getConfigAttr('backgroundColor', {})" @change="setAttrValue('backgroundColor', $event)"/>
      </div>
      <div class="mb-3">
        <InputImageUpload title="Image de fond" :value="getConfigAttr('backgroundImage', '')" @change="setAttrValue('backgroundImage', $event)"/>
      </div>
    </Card>
    <div class="mb-3">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="toggle-fullwidth" :checked="getConfigAttr('border', false)" @change="setAttrValue('border', $event.target.checked)">
        <label class="form-check-label" for="toggle-fullwidth">Activer / Désactiver le border</label>
      </div>
    </div>
    <Card title="Border" class="mb-3" :title-size="6" v-if="voteConfig.border">
      <div class="mb-3">
        <label>Couleur du border</label>
        <InputColorPicker Label="Couleur du border" :value="getConfigAttr('borderColor', {})" @change="setAttrValue('borderColor', $event)"/>
      </div>
    </Card>
    <Card title="Police" class="mb-3" :title-size="6">
      <div class="mb-3">
        <label>Couleur de la police</label>
        <InputColorPicker Label="Couleur de la police" :value="getConfigAttr('fontColor', {})" @change="setAttrValue('fontColor', $event)"/>
      </div>
      <div class="mb-3">
        <FontSelector label="Police" :value="getConfigAttr('fontFamily', '')" @change="setAttrValue('fontFamily', $event)" :imported-fonts="googleFonts"/>
      </div>
    </Card>
    <div class="row mb-3">
      <div class="col">
        <label class="small font-weight-bold bmd-label-static" for="valueType">Type de valeurs</label>
        <select class="form-control" :value="getConfigAttr('valueStyle', 'percent')" @change="setAttrValue('valueStyle', $event.target.value)" id="valueType">
          <option value="percent" >Pourcentages</option>
          <option value="hide" >Cacher les valeurs</option>
          <option value="count" >Numéraire (total)</option>
        </select>
      </div>
      <div class="col">
        <label class="small font-weight-bold bmd-label-static" for="renderType">Type de rendu</label>
        <select class="form-control" :value="getConfigAttr('renderType', 'bars')" @change="setAttrValue('renderType', $event.target.value)" id="renderType">
          <option value="bars" >Bars</option>
          <option value="pieChart" >Camembert</option>
        </select>
      </div>
    </div>
    <Card v-if="getConfigAttr('renderType', 'pieChart') === 'pieChart'" title="Camembert" :title-size="6" class="mb-3">
      <div class="mb-3">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="toggle-legend" :checked="getConfigAttr('pieChartDisplayLegend', false)" @change="setAttrValue('pieChartDisplayLegend', $event.target.checked)">
          <label class="form-check-label" for="toggle-legend">Afficher la legende</label>
        </div>
      </div>
      <div v-if="getConfigAttr('pieChartDisplayLegend', false)" class="mb-3">
        <label class="small font-weight-bold bmd-label-static" for="legendPosition">Position de la legende</label>
        <select class="form-control" :value="getConfigAttr('pieChartLegendPosition', 'left')" @change="setAttrValue('pieChartLegendPosition', $event.target.value)" id="legendPosition">
          <option value="left">Gauche</option>
          <option value="top">Au dessus</option>
          <option value="right">Droite</option>
          <option value="bottom">En dessous</option>
        </select>
      </div>
      <Card title="Couleurs des reponses" :title-size="6" class="mb-3">
        <div class="row mb-2" v-for="(col, index) in getConfigAttr('answersColors', [])" :key="index">
          <div class="col">
            <inputColorPicker :value="getConfigAttr('answersColors', [])[index]" @change="changeColorAt(index, $event)"/>
          </div>
          <div class="col-auto ps-0">
            <button class="btn btn-danger text-light" @click="removeColor(index)"><i class="fas fa-trash"></i></button>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12 text-center">
            <button class="btn btn-success text-light btn-sm" @click="addColor"><i class="fas fa-plus me-2"></i>Ajouter une couleur</button>
          </div>
        </div>
      </Card>
      <div class="mb-3">
        <label>Couleur de la bonne réponse</label>
        <InputColorPicker Label="Couleur de la bonne réponse" :value="getConfigAttr('barGoodAnswerColor', {})" @change="setAttrValue('barGoodAnswerColor', $event)"/>
      </div>
    </Card>
    <Card v-if="getConfigAttr('renderType', 'bars') === 'bars'" title="Bars" :title-size="6" class="mb-3">
      <div class="mb-3">
        <label>Emplacement du texte de réponses</label>
        <select class="form-control" :value="getConfigAttr('answerTextPosition', '')" @change="setAttrValue('answerTextPosition', $event.target.value)">
          <option value="">Au dessus</option>
          <option value="left">A coté</option>
        </select>
      </div>
      <div class="mb-3">
        <label>Couleur des barres</label>
        <InputColorPicker Label="Couleur des barres" :value="getConfigAttr('barColor', {})" @change="setAttrValue('barColor', $event)"/>
      </div>
      <div class="mb-3">
        <label>Couleur du fond des barres</label>
        <InputColorPicker Label="Couleur du fond des barres" :value="getConfigAttr('backgroundBarColor', {})" @change="setAttrValue('backgroundBarColor', $event)"/>
      </div>
      <div class="mb-3">
        <label>Couleur de la bonne réponse</label>
        <InputColorPicker Label="Couleur de la bonne réponse" :value="getConfigAttr('barGoodAnswerColor', {})" @change="setAttrValue('barGoodAnswerColor', $event)"/>
      </div>
      <div class="mb-3">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="toggle-answersIndex" :checked="getConfigAttr('showIndex', false)" @change="setAttrValue('showIndex', $event.target.checked)">
          <label class="form-check-label" for="toggle-answersIndex">Afficher le numéro des reponses</label>
        </div>
      </div>
      <div>
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="toggle-animations" :checked="getConfigAttr('orderAnimation', false)" @change="setAttrValue('orderAnimation', $event.target.checked)">
          <label class="form-check-label" for="toggle-animations">Animations de l'ordre des résultats</label>
        </div>
      </div>
    </Card>
    <div class="mb-3">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="toggle-timer" :checked="getConfigAttr('isTimer', false)" @change="setAttrValue('isTimer', $event.target.checked)">
        <label class="form-check-label" for="toggle-timer">Activer / Désactiver le timer</label>
      </div>
    </div>
    <Card title="Timer" :title-size="6" v-if="voteConfig.isTimer">
      <div class="mb-3">
        <label class="" for="timerDuration">Durée du timer (en sec)</label>
        <input :value="getConfigAttr('timerDuration', 15)" @change="setAttrValue('timerDuration', parseInt($event.target.value))" id="timerDuration" type="number" class="form-control">
      </div>
      <div class="mb-3">
        <label>Couleur du timer</label>
        <InputColorPicker Label="Couleur du timer" :value="getConfigAttr('timerColor', {})" @change="setAttrValue('timerColor', $event)"/>
      </div>
      <div class="mb-3">
        <label for="valueType">Alignement du timer</label>
        <select class="form-control" id="timerAlignment" :value="getConfigAttr('timeAlignment', 'text-start')" @change="setAttrValue('timeAlignment', $event.target.value)">
          <option value="text-start" >Gauche</option>
          <option value="text-center" >Centre</option>
          <option value="text-end" >Droite</option>
        </select>
      </div>
    </Card>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import InputColorPicker from '../../utilities/InputColorPicker.vue';
import InputImageUpload from '../../utilities/InputImageUpload.vue';
import FontSelector from '../../utilities/FontSelector.vue';
import Card from '../../utilities/Card.vue';

export default {
  name: 'VoteLeftBar',
  components: {
    InputImageUpload,
    InputColorPicker,
    FontSelector,
    Card,
  },
  computed: {
    ...mapGetters(['googleFonts', 'voteConfig']),
  },
  methods: {
    getConfigAttr(attrName, defaultValue)
    {
      return this.voteConfig[attrName] !== undefined ? this.voteConfig[attrName] : defaultValue;
    },
    setAttrValue(attrName, value)
    {
      const out = { ...this.voteConfig };
      out[attrName] = value;
      this.$store.commit('setVoteConfig', out);
    },
    removeColor(idx)
    {
      this.$store.commit('removeColorFromVotePieChartConfig', idx);
    },
    addColor()
    {
      this.$store.commit('addColorToVotePieChartConfig', '#ffffff');
    },
    changeColorAt(index, color)
    {
      const out = { ...this.voteConfig };
      out.answersColors[index] = color;
      this.$store.commit('setVoteConfig', out);
    },
  },
};

</script>

<style scoped>

</style>
