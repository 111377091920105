<template>
  <div class="row">
    <div class="col-12" >
      <h3 :class="timeAlignment" :style="textStyle">{{ timeLeft }} <span class='timer-unit'>sec</span></h3>
    </div>
    <div class="col-12">
      <div class="progress h-100" :style="progressStyle">
        <div class="progress-bar" role="progressbar" :style="progressBarStyle" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'VoteBoardTimer',
  props: {
    color: String,
    timeMax: Number,
    timeLeft: Number,
    timeAlignment: String,
  },
  computed: {
    textStyle() {
      return {
        color: this.color,
      };
    },
    progressStyle() {
      return {
        borderRadius: '3px',
        backgroundColor: 'transparent',
      };
    },
    progressBarStyle() {
      return {
        height: '5px',
        width: `${(this.timeLeft * 100) / this.timeMax}%`,
        backgroundColor: this.color,
      };
    },
  },
};

</script>

<style scoped>

</style>
