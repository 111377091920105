/**
 * TODO DOC
 */
const QuestionConfig = {
  backgroundImage: 'https://captag-interactions.web.app/assets/FondQuestion.png',
  border: true,
  fontFamily: '',
  fontSize: '44px',
  author: true,
  borderColor: {
    rgba: {
      r: 250,
      g: 167,
      b: 37,
      a: 1,
    },
    hex: '#FAA725',
  },
  fontColor: {
    rgba: {
      r: 0,
      g: 0,
      b: 0,
      a: 255,
    },
    hex: '#000000',
  },
  backgroundColor: {
    rgba: {
      r: 255,
      g: 255,
      b: 255,
      a: 255,
    },
    hex: '#ffffff',
  },
};

export default QuestionConfig;
