<template>
  <div class="pe-3">
    <div class="mb-3">
      <label>Couleur de fond</label>
      <InputColorPicker Label="Couleur du fond" :value="getConfigAttr('backgroundColor', {})" @change="setAttrValue('backgroundColor', $event)" />
    </div>
    <div class="mb-3">
      <InputImageUpload title="Image de fond" :value="getConfigAttr('backgroundImage', '')" @change="setAttrValue('backgroundImage', $event)"/>
    </div>
    <div class="mb-3">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="toggle-fullwidth" v-model="border">
        <label class="form-check-label" for="toggle-fullwidth">Activer / Désactiver le border</label>
      </div>
    </div>
    <template v-if="border">
      <div class="mb-3">
        <label>Couleur du border</label>
        <InputColorPicker Label="Couleur du border" :value="getConfigAttr('borderColor', {})" @change="setAttrValue('borderColor', $event)"/>
      </div>
    </template>
    <div class="mb-3">
      <FontSelector label="Police"  :value="getConfigAttr('fontFamily', '')" @change="setAttrValue('fontFamily', $event)" :imported-fonts="googleFonts"/>
    </div>
    <div class="mb-3">
      <label>Couleur de la police</label>
      <InputColorPicker Label="Couleur de la police" :value="getConfigAttr('fontColorMain', {})" @change="setAttrValue('fontColorMain', $event)"/>
    </div>
    <div class="mb-3">
      <label>Couleur des nombres</label>
      <InputColorPicker Label="Couleur des nombres" :value="getConfigAttr('fontColorAlt', {})" @change="setAttrValue('fontColorAlt', $event)"/>
    </div>
    <div class="mb-3">
      <label for="fontSize">Taille des textes</label>
      <input :value="getConfigAttr('fontSize', 21)" @change="setAttrValue('fontSize', parseInt($event.target.value))" id="fontSize" type="number" class="form-control" placeholder="21">
    </div>
    <div class="mb-3">
      <label for="textAlign">Justification du texte</label>
      <select class="form-control" :value="getConfigAttr('textAlign', 'text-center')" @change="setAttrValue('textAlign', $event.target.value)" id="textAlign">
        <option value="text-start" >Gauche</option>
        <option value="text-center" >Centré</option>
        <option value="text-end" >Droite</option>
      </select>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="toggle-fullwidth" v-model="showPoints">
          <label class="form-check-label" for="toggle-fullwidth">Afficher points</label>
        </div>
      </div>
      <div class="col">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="toggle-fullwidth" v-model="showPosition">
          <label class="form-check-label" for="toggle-fullwidth">Afficher positions</label>
        </div>
      </div>
    </div>
    <div class="mb-3">
      <label for="animID">ID Animation</label>
      <input :value="getConfigAttr('animID', '')" @change="setAttrValue('animID', $event.target.value)" id="animID" type="text" class="form-control" placeholder="ID de l'animation">
    </div>
    <div class="mb-3">
      <label for="animID">Donnée d'équipe</label>
      <input :value="getConfigAttr('teamData', '')" @change="setAttrValue('teamData', $event.target.value)" id="animID" type="text" class="form-control" placeholder="ID de la donnée">
    </div>
    <div class="mb-3">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="toggle-fullwidth" v-model="teamAverage">
        <label class="form-check-label" for="toggle-fullwidth">Moyenne par équipe</label>
      </div>
    </div>
    <div class="mb-3">
      <label for="rankingLength">Nombre de lignes</label>
      <input :value="getConfigAttr('rankingLength', 10)" @change="setAttrValue('rankingLength', parseInt($event.target.value))" id="rankingLength" type="number" class="form-control" placeholder="10">
    </div>
    <div class="mb-3">
      <label for="rankingLength">Fréquence de rafraichissement <span class="small text-muted">Sec</span></label>
      <input :value="getConfigAttr('refreshFreq', 5)" @change="setAttrValue('refreshFreq', parseInt($event.target.value))" id="rankingLength" type="number" min="1" max="30" class="form-control" placeholder="Fréquence de rafraichissement (sec)">
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import InputColorPicker from '../../utilities/InputColorPicker.vue';
import InputImageUpload from '../../utilities/InputImageUpload.vue';
import FontSelector from '../../utilities/FontSelector.vue';

export default {
  name: 'RankingLeftBar',
  components: {
    InputImageUpload,
    InputColorPicker,
    FontSelector,
  },
  computed: {
    ...mapGetters(['googleFonts', 'rankingConfig']),
    border: {
      get()
      {
        return this.rankingConfig.border || false;
      },
      set(newValue)
      {
        this.$store.commit('setRankingConfig', { ...this.rankingConfig, border: newValue });
      },
    },
    showPoints: {
      get()
      {
        return this.rankingConfig.showPoints || false;
      },
      set(newValue)
      {
        this.$store.commit('setRankingConfig', { ...this.rankingConfig, showPoints: newValue });
      },
    },
    showPosition: {
      get()
      {
        return this.rankingConfig.showPosition || false;
      },
      set(newValue)
      {
        this.$store.commit('setRankingConfig', { ...this.rankingConfig, showPosition: newValue });
      },
    },
    teamAverage: {
      get()
      {
        return this.rankingConfig.teamAverage || false;
      },
      set(newValue)
      {
        this.$store.commit('setRankingConfig', { ...this.rankingConfig, teamAverage: newValue });
      },
    },
  },
  methods: {
    getConfigAttr(attrName, defaultValue)
    {
      return this.rankingConfig[attrName] || defaultValue;
    },
    setAttrValue(attrName, value)
    {
      const out = { ...this.rankingConfig };
      out[attrName] = value;
      this.$store.commit('setRankingConfig', out);
    },
  },
};

</script>

<style scoped>

</style>
