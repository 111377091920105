import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import actions from './actions';
import state from './state';
import getters from './getters';

Vue.use(Vuex);

/**
 * Program Store Object
 * @type {Store<unknown>}
 */
const vuex = new Vuex.Store({
  strict: false,
  state,
  mutations,
  actions,
  getters,
});

vuex.watch((_state) => _state.screenAction.toDo, () => vuex.dispatch('processCommand'));

/**
 * This watch is used to apply the custom CSS upon state's customCSS changes
 * This will create a <style></style> element and put it to the head of the document with the user custom CSS
 * If a old custom CSS is already present in the head, the new one will replace it
 */
vuex.watch((_state) => _state.customCSS, () => {
  const css = state.customCSS.value.replace('\n', '');
  const head = document.head || document.getElementsByTagName('head')[0];
  const style = document.createElement('style');
  style.id = 'customCSS';
  // remove the current customCSS if present
  if (document.getElementById('customCSS') != null)
    head.removeChild(document.getElementById('customCSS'));
  if (state.customCSS.value === '')
    return;
  head.appendChild(style);
  style.type = 'text/css';
  if (style.styleSheet)
    style.styleSheet.cssText = css;// This is required for IE8 and below.
  else
    style.appendChild(document.createTextNode(css));
  console.log('Updated custom CSS');
});

export default vuex;
