<template>
  <div id="VoteBoard" class="background h-100 d-flex justify-content-center align-items-center" :style="backgroundStyle">
    <div class="row h-100 w-100">
      <div class="col-12" :class="isShowAnswers ? 'h-50' : 'h-100'">
        <div class="h-100 w-100">
          <div class="row questionRow d-flex align-items-center">
            <div class="col-12 px-0 d-flex align-items-center questionColumn py-5" :style="questionBackgroundStyle">
              <h3 class="text-center w-100 px-5 mx-5" :style="QuestionStyle">{{ currentVote.question }}</h3>
            </div>
          </div>
          <transition name="timerfade">
            <div v-if="isShowTimer" class="row timerRow">
              <div class="col-12">
                <VoteBoardTimer
                  :timeAlignment="voteBoardConfig.timeAlignment"
                  :timeMax="voteBoardConfig.timerDuration"
                  :timeLeft="voteBoardConfig.timerDuration - ((this.t < voteBoardConfig.timerDuration) ? this.t : voteBoardConfig.timerDuration)"
                  :color="voteBoardConfig.timerColor.hex"
                  class="h-100 p-2"/>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <transition name="timerfade">
        <div v-if="isShowAnswers" class="col-12 h-50 py-4">
        <div class="row" :class="mainChoiceRowHeightClass">
          <div class="col">
            <div class="h-100 w-100">
              <VoteBoardBar :class="barClass(0)" :pictoSrc="getChoiceByIndex(0).url" :progress="getProgressByIndex(0)" class="h-100 p-2" :color="getChoiceByIndex(0).color" :text="getChoiceByIndex(0).text"/>
            </div>
          </div>
          <div v-if="this.choicesCount >= 2" class="col">
            <div class="h-100 w-100">
              <VoteBoardBar :class="barClass(1)" :pictoSrc="getChoiceByIndex(1).url" :progress="getProgressByIndex(1)" class="h-100 p-2" :color="getChoiceByIndex(1).color" :text="getChoiceByIndex(1).text"/>
            </div>
          </div>
        </div>
        <div v-if="this.choicesCount >= 3" class="row h-50">
          <div class="col">
            <div class="h-100 w-100">
              <VoteBoardBar :class="barClass(2)" :pictoSrc="getChoiceByIndex(2).url" :progress="getProgressByIndex(2)" class="h-100 p-2" :color="getChoiceByIndex(2).color" :text="getChoiceByIndex(2).text"/>
            </div>
          </div>
          <div v-if="this.choicesCount === 4" class="col">
            <div class="h-100 w-100">
              <VoteBoardBar :class="barClass(3)" :pictoSrc="getChoiceByIndex(3).url" :progress="getProgressByIndex(3)" class="h-100 p-2" :color="getChoiceByIndex(3).color" :text="getChoiceByIndex(3).text"/>
            </div>
          </div>
        </div>
      </div>
      </transition>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import VoteBoardBar from './VoteBoardBar.vue';
import VoteBoardTimer from './VoteBoardTimer.vue';

export default {
  name: 'VoteBoard',
  components: { VoteBoardTimer, VoteBoardBar },
  data() {
    return {
      t: 0,
    };
  },
  mounted() {
    window.setInterval(() => {
      if (this.t < 25 && this.currentCommand === 'display-stats-voteboard')
        this.t += 1;
    }, 1000);
    this.$store.watch((_state, _getters) => _getters.currentCommand, (newVal, oldVal) => {
      this.t = 0;
    });
  },
  computed: {
    ...mapGetters(['voteBoardConfig', 'currentCommand', 'polls', 'pollsAnswers', 'targetID']),
    questionBackgroundStyle() {
      return {
        backgroundColor: this.voteBoardConfig.QuestionBackgroundColor.hex,
      };
    },

    mainChoiceRowHeightClass()
    {
      if (this.choicesCount > 2)
        return 'h-50';
      return 'h-100';
    },

    /**
     * Return the current vote depend on the command or an empty object if no vote is specified by the command
     * @return {{question: string, statsVisible: boolean, isLocked: boolean, isVisible: boolean, type: string}|*}
     */
    currentVote()
    {
      // check if polls is not empty
      if (this.polls &&
        Object.keys(this.polls).length === 0 &&
        this.polls.constructor === Object)
        return {
          isLocked: false,
          isVisible: true,
          question: '',
          statsVisible: false,
          type: 'single',
        };
      return this.polls[this.targetID];
    },

    choicesCount()
    {
      return Object.keys(this.currentVote.choices).length;
    },

    isShowTimer()
    {
      return this.voteBoardConfig.isTimer && this.currentCommand !== 'display-question-voteboard';
    },

    isShowAnswers()
    {
      return this.$store.getters.currentCommand !== 'display-question-voteboard';
    },

    /**
     * Background style binding
     * @return {{backgroundColor, background: string}}
     */
    backgroundStyle()
    {
      return {
        background: `url("${this.voteBoardConfig.backgroundImage}") no-repeat center center/cover fixed`,
        backgroundColor: this.voteBoardConfig.backgroundColor.hex,
      };
    },

    /**
     * Global font size computing
     * if the input is only composed of number, the computed will return the value + px
     * and return the full input otherwise
     * @return {string}
     */
    questionFontSize()
    {
      if (/^\d+$/.test(this.voteBoardConfig.questionSize))
        return `${this.voteBoardConfig.questionSize}px`;
      return this.voteBoardConfig.questionSize;
    },

    QuestionStyle() {
      return {
        color: this.voteBoardConfig.fontColor.hex,
        fontSize: this.questionFontSize,
        fontFamily: this.voteBoardConfig.fontFamily,
      };
    },
  },
  methods: {
    getChoiceByIndex(index)
    {
      let output = { text: '' };
      if (index >= this.choicesCount)
        return output;
      Object.keys(this.currentVote.choices).forEach((choice, choiceIndex) => {
        if (choiceIndex === index)
          output = Object.assign(output, this.currentVote.choices[choice]);
      });
      if (this.currentCommand === 'display-good-answers-voteboard' && this.isIndexGoodAnswer(index))
        output.color = this.voteBoardConfig.goodAnswerColor.hex;
      return output;
    },

    isIndexGoodAnswer(index)
    {
      let k = '';
      Object.keys(this.currentVote.choices).forEach((choice, choiceIndex) => {
        if (choiceIndex === index)
          k = choice;
      });
      let good = false;
      this.currentVote.goods.forEach((value) => {
        if (value === k)
          good = true;
      });
      return good;
    },
    /**
     *
     */
    getProgressByIndex(index)
    {
      if ((this.currentCommand !== 'display-stats-voteboard' &&
        this.currentCommand !== 'display-good-answers-voteboard') ||
        index >= this.choicesCount)
        return 100;
      let k = '';
      Object.keys(this.currentVote.choices).forEach((choice, choiceIndex) => {
        if (choiceIndex === index)
          k = choice;
      });
      return (this.getAnswerCountForAnswer(k) * 100) / ((this.getTotalAnswerCount() > 0) ? this.getTotalAnswerCount() : 1);// avoid dividing by 0
    },

    /**
     * Return the count of answer for the current Question and the given answer
     * @param ansKey the answer Identifier
     * @return {number}
     */
    getAnswerCountForAnswer(ansKey)
    {
      // check if answers are empty
      if (this.pollsAnswers[this.targetID] === undefined)
        return 0;
      let count = 0;
      Object.entries(this.pollsAnswers[this.targetID]).forEach(([, value]) => {
        value.forEach((rep) => {
          if (rep === ansKey)
            count += 1;
        });
      });
      return count;
    },

    /**
     *  Return the total answer count for the current question
     *  @return {number}
     */
    getTotalAnswerCount()
    {
      // check if answers are empty
      if (this.pollsAnswers[this.targetID] === undefined)
        return 0;
      return Object.keys(this.pollsAnswers[this.targetID]).length;
    },

    barClass(index)
    {
      if (this.currentCommand === 'display-good-answers-voteboard' && this.isIndexGoodAnswer(index))
      {
        return 'animate__animated animate__pulse';
      }
      return '';
    },
  },
};

</script>

<style scoped>

.timerRow {
  height: 15%;
}

.questionRow {
  height: 85%;
}

.timerfade-enter-active/*, .timerfade-leave-active*/ {
  transition: opacity .5s;
}

.timerfade-enter/*, .timerfade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>
