<template>
  <div class="pe-3">
    <div class="mb-3">
      <label>Couleur de fond</label>
      <InputColorPicker Label="Couleur du fond" :value="getConfigAttr('backgroundColor', {})" @change="setAttrValue('backgroundColor', $event)"/>
    </div>
    <div class="mb-3">
      <InputImageUpload title="Image de fond" :value="getConfigAttr('backgroundImage', '')" @change="setAttrValue('backgroundImage', $event)"/>
    </div>
    <div class="mb-3">
      <label>Couleur de la police</label>
      <InputColorPicker Label="Couleur de la police" :value="getConfigAttr('fontColor', {})" @change="setAttrValue('fontColor', $event)"/>
    </div>
    <div class="mb-3">
      <FontSelector label="Police" :imported-fonts="googleFonts" :value="getConfigAttr('fontFamily', '')" @change="setAttrValue('fontFamily', $event)"/>
    </div>
    <div class="mb-3">
      <label for="fontSize">Taille des textes</label>
      <input :value="getConfigAttr('fontSize', 21)" @change="setAttrValue('fontSize', parseInt($event.target.value))" id="fontSize" type="number" class="form-control" placeholder="21">
    </div>
    <Card title="Configuration de la jauge" :title-size="6">
      <div class="mb-3">
        <InputImageUpload title="Image de la jauge" :value="getConfigAttr('jaugeImage', '')" @change="setAttrValue('jaugeImage', $event)"/>
      </div>
      <div class="mb-3">
        <InputImageUpload title="Image de l'aiguille" :value="getConfigAttr('needleImage', '')" @change="setAttrValue('needleImage', $event)"/>
      </div>
    </Card>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import InputImageUpload from '../../utilities/InputImageUpload.vue';
import InputColorPicker from '../../utilities/InputColorPicker.vue';
import FontSelector from '../../utilities/FontSelector.vue';
import Card from '../../utilities/Card.vue';

export default {
  name: 'VoteLeftBar',
  components: {
    Card,
    InputImageUpload,
    InputColorPicker,
    FontSelector,
  },
  computed: {
    ...mapGetters(['googleFonts', 'jaugeConfig']),
  },
  methods: {
    getConfigAttr(attrName, defaultValue)
    {
      return this.jaugeConfig[attrName] || defaultValue;
    },
    setAttrValue(attrName, value)
    {
      const out = { ...this.jaugeConfig };
      out[attrName] = value;
      this.$store.commit('setJaugeConfig', out);
    },
  },
};

</script>

<style scoped>

</style>
