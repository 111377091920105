/**
 * TODO DOC
 */
const BreakTheWallConfig = {
  backgroundImage: '',
  backgroundColor: {
    rgba: {
      r: 0,
      g: 0,
      b: 0,
      a: 255,
    },
  },
  foregroundImage: '',
  ballSpeed: 100,
  MaxBalls: 35,
  tileDrop: false,
  endBehavior: 'reloadFade',
  timeBeforeFade: 5,
  gridSize: {
    height: 30,
    width: 30,
  },
  ballScale: {
    start: 100,
    end: 25,
  },
};

export default BreakTheWallConfig;
