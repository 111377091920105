<template>
  <div id="Vote" class="h-100 align-items-center background row m-0" :style="backgroundStyle">
    <div class="col-12 m-0 p-0">
      <div class="mainBorder p-4 mx-auto" :style="borderStyle">
        <div class="mainRow row p-0 m-0">
          <div class="col-12">
            <h3 v-html="currentVote.question" :style="questionStyle" class="questionText"></h3>
          </div>
          <template v-if="isShowAnswers">
              <hr :style="separatorStyle" class="col-12" id="separator">
            <div class="responsesContent col-12">
              <VotePieChartRender v-if="voteConfig.renderType === 'pieChart'" :answers="answers" :choices="currentChoices" :question="currentVote"/>
              <VoteBarRender v-else :answers="answers" :choices="currentChoices" :question="currentVote"/>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="position-absolute w-75 vote-timer" style="bottom: 10px;left: 12%;" v-if="voteConfig.isTimer">
      <VoteBoardTimer
        v-if="currentCommand === 'display-question-answers'"
        :timeAlignment="voteConfig.timeAlignment"
        :timeMax="parseInt(voteConfig.timerDuration)"
        :timeLeft="voteConfig.timerDuration - ((this.t < voteConfig.timerDuration) ? this.t : voteConfig.timerDuration)"
        :color="(voteConfig.timerColor) ? voteConfig.timerColor.hex : '#000000'"
        class="my-2"/>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import VoteBoardTimer from '../VoteBoard/VoteBoardTimer.vue';
import VoteBarRender from './VoteBarRender.vue';
import VotePieChartRender from './VotePieChartRender.vue';

export default {
  name: 'Vote',
  components: { VotePieChartRender, VoteBarRender, VoteBoardTimer },
  data()
  {
    return {
      t: 0,
    };
  },
  mounted() {
    window.setInterval(() => {
      if (this.t < this.voteConfig.timerDuration && this.currentCommand === 'display-question-answers')
        this.t += 1;
    }, 1000);
    this.$store.watch((_state, _getters) => this.currentCommand, () => {
      this.t = 0;
    });
  },
  computed: {
    ...mapGetters(['voteConfig', 'currentCommand', 'polls', 'pollsAnswers', 'targetID']),
    /**
     * Return true if the command ask to show the answer Texts
     * @return {boolean}
     */
    isShowAnswers()
    {
      return this.currentCommand !== 'display-question';
    },

    /**
     * Return the current vote depend on the command or an empty object if no vote is specified by the command
     * @return {{question: string, statsVisible: boolean, isLocked: boolean, isVisible: boolean, type: string}|*}
     */
    currentVote()
    {
      // check if polls is not empty
      if (!this.polls || Object.keys(this.polls).length <= 0 || !this.polls[this.targetID])
        return {
          isLocked: false,
          isVisible: true,
          question: '',
          statsVisible: false,
          type: 'single',
        };
      return this.polls[this.targetID] || {};
    },

    currentChoices()
    {
      const out = [];
      if (!this.currentVote.choices)
        return [];
      Object.entries(this.currentVote.choices).forEach(([key, value]) => {
        out.push({ ...value, id: key });
      });
      return out;
    },

    answers()
    {
      if (!this.pollsAnswers[this.targetID])
        return {};
      return this.pollsAnswers[this.targetID] || {};
    },

    /**
     * Background style binding
     * @return {{backgroundColor, background: string}}
     */
    backgroundStyle()
    {
      return {
        background: `url("${this.voteConfig.backgroundImage}") no-repeat center center/cover fixed`,
        backgroundColor: this.voteConfig.backgroundColor.hex,
      };
    },

    /**
     * Border style binding
     */
    borderStyle()
    {
      const out = {
        borderRadius: '30px',
        border: '0px solid black',
        maxWidth: '80%',
        minWidth: '80%',
      };
      if (this.voteConfig.border && this.voteConfig.borderColor && this.voteConfig.borderColor.hex8)
        out.backgroundColor = this.voteConfig.borderColor.hex8 || '';
      return out;
    },

    /**
     * separator style binding
     * @return {{borderTop: string}}
     */
    separatorStyle()
    {
      return {
        borderTop: `3px solid ${this.voteConfig.fontColor.hex}`,
      };
    },

    /**
     * Vote Question text style binding
     * @return {{fontFamily: string, color: string, fontSize: (string|string)}}
     */
    questionStyle()
    {
      return {
        color: this.voteConfig.fontColor.hex,
        fontSize: this.fontSize,
        fontFamily: this.voteConfig.fontFamily,
      };
    },

    /**
     * Global font size computing
     * if the input is only composed of number, the computed will return the value + px
     * and return the full input otherwise
     * @return {string}
     */
    fontSize()
    {
      if (/^\d+$/.test(this.voteConfig.fontSize))
        return `${this.voteConfig.fontSize}px`;
      return this.voteConfig.fontSize;
    },
  },
};

</script>

<style scoped>

</style>
