<template>
  <div id="Ranking" class="background h-100 d-flex justify-content-center" :style="backgroundStyle">
    <div class="container listContainer my-auto">
      <transition-group id="RankingList" name="flip-list" tag="ul">
        <li v-for="(value, key) in currentRanking.slice(0, rankingConfig.rankingLength || currentRanking.length)" :key="`${value[0]}_${key}`">
          <div :style="rowBorderStyle" class="row mt-1 align-items-center lineBorder">
            <div v-if="rankingConfig.showPosition" class="col-1 text-center leftValue">
              <h3 :style="textStyleAlt">{{ key + 1 }}</h3>
            </div>
            <div :class="rowBorderClass">
              <h3 :class="mainTextClass" :style="textStyleMain">{{ value[0] }}</h3>
            </div>
            <div v-if="rankingConfig.showPoints" class="col-1 text-center rightValue">
              <h3 :style="textStyleAlt">{{ value[1] }}</h3>
            </div>
          </div>
        </li>
      </transition-group>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'Ranking',
  data()
  {
    return {
      interval: null,
      awaitingUpdateResponse: false,
    };
  },
  beforeDestroy()
  {
    if (this.interval != null)
    {
      clearInterval(this.interval);
    }
  },
  created()
  {
    this.$store.watch(() => this.rankingConfig, () => {
      this.createUpdateInterval();
    });
    this.createUpdateInterval();
  },
  computed: {
    ...mapGetters(['currentRanking', 'rankingConfig']),
    backgroundStyle()
    {
      return {
        background: `url("${this.rankingConfig.backgroundImage}") no-repeat center center/cover fixed`,
        backgroundColor: this.rankingConfig.backgroundColor.hex,
      };
    },
    rowBorderStyle()
    {
      const out = {
        minHeight: '95px',
        borderRadius: '15px',
      };
      if (this.rankingConfig.borderColor && this.rankingConfig.borderColor.hex8)
        out.backgroundColor = this.rankingConfig.borderColor.hex8;
      return out;
    },
    rowBorderClass()
    {
      let col = 12;
      if (this.rankingConfig.showPosition)
        col = col - 1;
      if (this.rankingConfig.showPoints)
        col = col - 1;
      return `text-center col-${col}`;
    },
    textStyleMain()
    {
      return {
        color: this.rankingConfig.fontColorMain.hex,
        fontSize: this.fontSize,
        fontFamily: this.rankingConfig.fontFamily,
      };
    },
    textStyleAlt()
    {
      return {
        color: this.rankingConfig.fontColorAlt.hex,
        fontSize: this.fontSize,
        fontFamily: this.rankingConfig.fontFamily,
      };
    },
    fontSize()
    {
      if (/^\d+$/.test(this.rankingConfig.fontSize))
        return `${this.rankingConfig.fontSize}px`;
      return this.rankingConfig.fontSize;
    },
    mainTextClass()
    {
      return `${this.rankingConfig.textAlign} centerValue`;
    },
  },
  methods: {
    createUpdateInterval()
    {
      if (this.interval)
        clearInterval(this.interval);
      const updateFreq = (this.rankingConfig.refreshFreq && this.rankingConfig.refreshFreq >= 1 ? this.rankingConfig.refreshFreq : 5) * 1000;
      this.$store.dispatch('updateRanking', { animID: this.rankingConfig.animID, teamData: this.rankingConfig.teamData, teamAverage: this.rankingConfig.teamAverage, limit: this.rankingConfig.rankingLength });
      this.interval = setInterval(() => {
        if (!this.awaitingUpdateResponse)
        {
          this.awaitingUpdateResponse = true;
          this.$store.dispatch('updateRanking', { animID: this.rankingConfig.animID, teamData: this.rankingConfig.teamData, teamAverage: this.rankingConfig.teamAverage, limit: this.rankingConfig.rankingLength })
            .then(() =>
            {
              this.awaitingUpdateResponse = false;
            });
        }
        else
          console.log('Update canceled, waiting for last update response');
      }, updateFreq);
      console.log(`Created update interval [${this.interval}] with ${updateFreq}ms.`);
    },
  },
};

</script>

<style scoped>

.flip-list-move {
  transition: transform 1s;
}

ul {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

</style>
