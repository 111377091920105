<template>
  <div id="Hide" class="background h-100 d-flex justify-content-center align-items-center" :style="backgroundStyle">
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'Hide',
  computed: {
    ...mapGetters(['hideConfig']),
    backgroundStyle() {
      const out = { backgroundColor: 'white' };
      if (!this.hideConfig)
        return out;
      if (this.hideConfig.backgroundImage)
        out.background = `url("${this.hideConfig.backgroundImage}") no-repeat center center/cover fixed`;
      if (this.hideConfig.backgroundColor && this.hideConfig.backgroundColor.hex8)
        out.backgroundColor = this.hideConfig.backgroundColor.hex8;
      return out;
    },
  },
};

</script>

<style scoped>

</style>
