<template>
  <div class="pe-3">
    <div class="mb-3">
      <label>Couleur de fond</label>
      <InputColorPicker Label="Couleur du fond" :value="getConfigAttr('backgroundColor', {})" @change="setAttrValue('backgroundColor', $event)"/>
    </div>
    <div class="mb-3">
      <InputImageUpload title="Image de fond" :value="getConfigAttr('backgroundImage', '')" @change="setAttrValue('backgroundImage', $event)"/>
    </div>
    <div class="mb-3">
      <FontSelector label="Police" :value="getConfigAttr('fontFamily', '')" @change="setAttrValue('fontFamily', $event)" :imported-fonts="googleFonts"/>
    </div>
    <div class="mb-3">
      <label>Orientation des polices</label>
      <div class="row">
        <div class="col-md-3">
          <button type="button"
                  :class="getConfigAttr('orientation', '') === 'horizontal' ? 'btn-primary': 'btn-secondary'"
                  @click="setAttrValue('orientation', 'horizontal')" class="btn v-btn v-item--active v-btn--active v-btn--flat v-btn--icon v-btn--round theme--light v-size--default"><span class="v-btn__content"><svg style="fill: currentcolor; height: 1em; width: 1em;" fill="currentColor" height="24" width="24" viewBox="0 0 12 12"><path d="M0 7 L0 5 L12 5 L12 7 Z"></path></svg></span></button>
        </div>
        <div class="col-md-3">
          <button type="button"
                  :class="getConfigAttr('orientation', '') === 'diagonal' ? 'btn-primary': 'btn-secondary'"
                  @click="setAttrValue('orientation', 'diagonal')" class="btn v-btn v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--default"><span class="v-btn__content"><svg style="fill: currentcolor; height: 1em; width: 1em;" fill="currentColor" height="24" width="24" viewBox="0 0 12 12"><path d="M0 7 L0 5 L12 5 L12 7 Z" transform="rotate(315 6 6)"></path></svg></span></button>
        </div>
        <div class="col-md-3">
          <button type="button"
                  :class="getConfigAttr('orientation', '') === 'cross' ? 'btn-primary': 'btn-secondary'"
                  @click="setAttrValue('orientation', 'cross')" class="btn v-btn v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--default"><span class="v-btn__content"><svg style="fill: currentcolor; height: 1em; width: 1em;" fill="currentColor" height="24" width="24" viewBox="0 0 12 12"><path d="M0 7 L0 5 L12 5 L12 7 Z"></path><path d="M0 7 L0 5 L12 5 L12 7 Z" transform="rotate(90 6 6)"></path></svg></span></button>
        </div>
        <div class="col-md-3">
          <button type="button"
                  :class="getConfigAttr('orientation', '') === 'allDirection' ? 'btn-primary': 'btn-secondary'"
                  @click="setAttrValue('orientation', 'allDirection')" class="btn v-btn v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--default"><span class="v-btn__content"><svg style="fill: currentcolor; height: 1em; width: 1em;" fill="currentColor" height="24" width="24" viewBox="0 0 12 12"><path d="M0 7 L0 5 L12 5 L12 7 Z"></path><path d="M0 7 L0 5 L12 5 L12 7 Z" transform="rotate(45 6 6)"></path><path d="M0 7 L0 5 L12 5 L12 7 Z" transform="rotate(90 6 6)"></path><path d="M0 7 L0 5 L12 5 L12 7 Z" transform="rotate(315 6 6)"></path></svg></span></button>
        </div>
      </div>
    </div>
    <div class="mb-3">
      <label class="mb-2">Couleurs des mots <span class="badge bg-success ms-2">{{ getConfigAttr('fontColor', []) ? getConfigAttr('fontColor', []).length : 0 }}</span></label>
      <div class="row mb-2" v-for="(col, index) in getConfigAttr('fontColor', [])" :key="index">
        <div class="col">
          <inputColorPicker :value="getConfigAttr('fontColor', [])[index]" @change="changeColorAt(index, $event)"/>
        </div>
        <div class="col-auto ps-0">
          <button class="btn btn-danger text-light" @click="removeColor(index)"><i class="fas fa-trash"></i></button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <button class="btn btn-success text-light" @click="addColor"><i class="fas fa-plus me-2"></i>Ajouter une couleur</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import InputImageUpload from '../../utilities/InputImageUpload.vue';
import InputColorPicker from '../../utilities/InputColorPicker.vue';
import FontSelector from '../../utilities/FontSelector.vue';
import wordCloudConfig from '../../../configurations/WordCloudConfig';

export default {
  name: 'WordCloudConfig',
  computed: {
    ...mapGetters(['wordCloudConfig', 'googleFonts']),
  },
  components: {
    InputImageUpload,
    InputColorPicker,
    FontSelector,
  },
  methods: {
    changeColorAt(index, color)
    {
      const out = { ...this.wordCloudConfig };
      out.fontColor[index] = color;
      this.$store.commit('setWordCloudConfig', out);
    },
    getConfigAttr(attrName, defaultValue)
    {
      return this.wordCloudConfig[attrName] || defaultValue;
    },
    setAttrValue(attrName, value)
    {
      const out = { ...this.wordCloudConfig };
      out[attrName] = value;
      this.$store.commit('setWordCloudConfig', out);
    },
    removeColor(idx) {
      this.$store.commit('removeColorFromWordCloudConfig', idx);
    },
    addColor() {
      this.$store.commit('addColorToWordcloudConfig', '#ffffff');
    },
  },
};

</script>

<style scoped>

.trash {
  color: white;
}

</style>
