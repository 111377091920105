import QuestionConfig from '../configurations/QuestionConfig';
import HideConfig from '../configurations/HideConfig';
import VoteConfig from '../configurations/VoteConfig';
import BreakTheWallConfig from '../configurations/BreakTheWallConfig';
import JaugeConfig from '../configurations/JaugeConfig';
import WordCloudConfig from '../configurations/WordCloudConfig';
import RankingConfig from '../configurations/RankingConfig';
import LiveChatConfig from '../configurations/LiveChatConfig';

export default {
  allConfig: (state) => ({
    WordCloudConfig: state.WordCloudConfig,
    QuestionConfig: state.QuestionConfig,
    VoteConfig: state.VoteConfig,
    HideConfig: state.HideConfig,
    RankingConfig: state.RankingConfig,
    EmojisConfig: state.EmojisConfig,
    LiveChatConfig: state.LiveChatConfig,
    BreakTheWallConfig: state.BreakTheWallConfig,
    VoteBoardConfig: state.VoteBoardConfig,
    GeneralConfig: state.GeneralConfig,
  }),
  generalConfig: (state) => state.GeneralConfig || {},
  questionConfig: (state) => ({ ...QuestionConfig, ...(state.QuestionConfig || {}) }),
  questionLoopInterval: (state) => state.remoteConfiguration.loop_interval || 5,
  hideConfig: (state) => ({ ...HideConfig, ...(state.HideConfig || {}) }),
  voteConfig: (state) => ({ ...VoteConfig, ...(state.VoteConfig || {}) }),
  breakTheWallConfig: (state) => {
    const out = { ...BreakTheWallConfig, ...(state.BreakTheWallConfig || {}) };
    if (state.BreakTheWallRemoteConfig && state.BreakTheWallRemoteConfig.ballUrl)
      out.ballUrl = state.BreakTheWallRemoteConfig.ballUrl;
    return out;
  },
  jaugeConfig: (state) => ({ ...JaugeConfig, ...(state.JaugeConfig || {}) }),
  wordCloudConfig: (state) => ({ ...WordCloudConfig, ...(state.WordCloudConfig || {}) }),
  rankingConfig: (state) => ({ ...RankingConfig, ...(state.RankingConfig || {}) }),
  liveChatConfig: (state) => ({ ...LiveChatConfig, ...(state.LiveChatConfig || {}) }),
  voteBoardConfig: (state) => ({ ...VoteConfig, ...(state.VoteBoardConfig || {}) }),
};
