<template>
  <div>
    <div class="mb-3">
      <label>Couleur de fond</label>
      <InputColorPicker Label="Couleur du fond" :value="hideConfig.backgroundColor" @change="$store.commit('setHideConfig', { ...hideConfig, backgroundColor: $event })"/>
    </div>
    <div class="mb-3">
      <InputImageUpload title="Image de fond" :value="hideConfig.backgroundImage" @change="$store.commit('setHideConfig', { ...hideConfig, backgroundImage: $event })"/>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import InputColorPicker from '../../utilities/InputColorPicker.vue';
import InputImageUpload from '../../utilities/InputImageUpload.vue';

export default {
  name: 'HideLeftBar',
  components: {
    InputImageUpload,
    InputColorPicker,
  },
  computed: {
    ...mapGetters(['hideConfig']),
  },
};

</script>

<style scoped>

</style>
