<template>
  <div id="question" class="background h-100 d-flex justify-content-center align-items-center" :style="backgroundStyle">
    <div class="mainBorder p-4" :style="borderStyle" >
      <div class="mainRow row p-0 m-0">
        <div class="questionCol col-12">
          <h3 :style="questionStyle" id="questionText" class="questionText" v-html="question.text"></h3>
        </div>
        <div class="col-12">
          <hr :style="separatorStyle" class="w-100 " id="separator">
        </div>
        <div v-if="isAuthor" class="authorInformation col-12">
          <div class="authorRow row">
            <p class="col-auto text-start likes" :style="dateStyle">{{ likesCount }} <i class="likeIcon far fa-thumbs-up" :style="likesStyle"></i></p>
            <p class="col text-center date" :style="dateStyle">{{ question.createdDate | formatDate }} </p>
            <p class="col-auto text-end authorName" :style="authorStyle">{{ question.author }}</p>
            <img v-if="isAvatar" class="mt-2 avatar" :style="avatarStyle" :src="question.avatar" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

const moment = require('moment');

export default {
  name: 'Question',
  data()
  {
    return {
      interval: undefined,
      currentLoopQuestion: '',
    };
  },
  filters: {
    /**
     * Date formatting.
     * this use the moment package to do that
     * val is a ms unix timestamp
     */
    formatDate(val)
    {
      if (!val) {
        return '-';
      }
      return moment(val).locale('fr').calendar();
    },
  },
  mounted()
  {
    clearInterval(this.interval);
    if (this.currentCommand === 'loop-messages')
    {
      this.updateLoopQuestion();
      this.interval = setInterval(this.updateLoopQuestion, this.questionLoopInterval * 1000);
    }
  },
  computed: {
    ...mapGetters([
      'getBorderStyle',
      'currentCommand',
      'questionConfig',
      'messages',
      'loopMessages',
      'questionLoopInterval',
      'targetID',
    ]),
    /**
     * @return the number of likes for the current message
     */
    likesCount()
    {
      return (this.question.likes) ? Object.keys(this.question.likes).length : 0;
    },

    /**
     * Return the color of the thumb on right of likes count
     */
    likesStyle()
    {
      return {
        color: this.questionConfig.fontColor.hex,
      };
    },

    questionID()
    {
      if (this.currentCommand === 'loop-messages')
        return this.currentLoopQuestion || undefined;
      return this.targetID || undefined;
    },

    /**
     * @Return the current question
     */
    question() {
      // check if message is not empty
      if (this.messages && Object.keys(this.messages).length > 0 && this.questionID)
        return this.messages[this.questionID];
      return {
        text: '',
        createdDate: '',
        author: '',
      };
    },

    /**
     * Return true if the author is known
     * @returns {boolean}
     */
    isAuthor() {
      return this.questionConfig.author;
    },

    /**
     * Return true if the author has an avatar
     * @returns {boolean}
     */
    isAvatar() {
      return this.question.avatar && this.question.avatar !== '';
    },

    /**
     * Background Style binding
     * @returns {{backgroundColor, background: string}}
     */
    backgroundStyle() {
      return {
        background: `url("${this.questionConfig.backgroundImage}") no-repeat center center/cover fixed`,
        backgroundColor: this.questionConfig.backgroundColor.hex,
      };
    },

    /**
     * Question text style binding
     * @return {{fontFamily: string, color: string, fontSize: (string|string)}}
     */
    questionStyle() {
      return {
        color: this.questionConfig.fontColor.hex,
        fontSize: this.fontSize,
        fontFamily: this.questionConfig.fontFamily,
      };
    },

    /**
     * Border style binding
     * @return {{border: string, backgroundColor: string, borderRadius: string, minWidth: string, maxWidth: string}}
     */
    borderStyle() {
      if (this.questionConfig.border)
        return this.getBorderStyle(this.questionConfig.borderConfig || {});
      return undefined;
    },

    /**
     * Separator style binding
     * @return {{borderTop: string}}
     */
    separatorStyle() {
      return {
        borderTop: `3px solid ${this.questionConfig.fontColor.hex}`,
      };
    },

    /**
     * date style binding
     * @return {{fontFamily: string, visibility: (string), color: string, fontSize: (string|string)}}
     */
    dateStyle() {
      return {
        visibility: this.isAuthor ? 'visible' : 'collapse',
        color: this.questionConfig.fontColor.hex,
        fontSize: this.fontSize,
        fontFamily: this.questionConfig.fontFamily,
      };
    },

    /**
     * Author Style binding
     * @return {{fontFamily: string, visibility: (string), color: string, fontSize: (string|string)}}
     */
    authorStyle()
    {
      return {
        visibility: this.questionConfig.author ? 'visible' : 'collapse',
        color: this.questionConfig.fontColor.hex,
        fontSize: this.fontSize,
        fontFamily: this.questionConfig.fontFamily,
      };
    },

    /**
     * Global font size computing
     * if the input is only composed of number, the computed will return the value + px
     * and return the full input otherwise
     * @return {string}
     */
    fontSize()
    {
      if (/^\d+$/.test(this.questionConfig.fontSize))
        return `${this.questionConfig.fontSize}px`;
      return this.questionConfig.fontSize;
    },

    /**
     * Avatar Style binding
     * @return {{borderRadius: string, width: string, height: string}}
     */
    avatarStyle()
    {
      return {
        borderRadius: '50%',
        height: this.fontSize,
        width: this.fontSize,
      };
    },
  },
  methods: {
    updateLoopQuestion()
    {
      if (!this.currentLoopQuestion)
        this.currentLoopQuestion = Object.keys(this.loopMessages).at(0);
      else
      {
        const idx = Object.keys(this.loopMessages).indexOf(this.currentLoopQuestion);
        if (idx < 0 || idx + 1 >= Object.keys(this.loopMessages).length)
          this.currentLoopQuestion = Object.keys(this.loopMessages).at(0);
        else
          this.currentLoopQuestion = Object.keys(this.loopMessages).at(idx + 1);
      }
    },
  },
};

</script>

<style scoped>
.avatar{
  padding-left: 0;
  padding-right: 0;
}
</style>
