<template>
  <div id="BreakTheWall" class="background h-100 d-flex justify-content-center align-items-center" :style="backgroundStyle">
  </div>
</template>

<script>

import * as PIXI from 'pixi.js';
import { mapGetters } from 'vuex';

const Chance = require('chance');

export default {
  name: 'BreakTheWall',
  data() {
    return {
      chance: new Chance(),
      pixiApp: null,
      pixiLoader: null,
      tiles: [],
      availableTiles: [],
      dropingTiles: [],
      balls: [],
      backgroundSprite: {},
      backgroundTexture: {},
      ballTexture: {},
    };
  },
  created()
  {
    window.addEventListener('resize', this.onResize);
  },
  destroyed()
  {
    window.removeEventListener('resize', this.onResize);
  },
  mounted()
  {
    this.createContext();
    this.loadAssets();
    this.$store.watch((_state) => this.breakTheWallConfig.foregroundImage, () => {
      this.loadAssets();
    });
    this.$store.watch((_state) => this.breakTheWallConfig.ballUrl, () => {
      this.loadAssets();
    });
    this.$store.watch((_state) => this.currentCommand, () => {
      this.createForegroundTiles();
      this.setTilesSize();
    });
    this.$store.watch((_state) => this.breakTheWallBalls, () => {
      this.addBallRandomPosition();
    });
    window.addEventListener('keypress', (e) => {
      if (e.key === 'b')
        this.addBallRandomPosition();
      if (e.key === 't')
      {
        for (let i = 0; i < this.availableTiles.length; i = i + 1)
          this.addBallRandomPosition();
      }
    });
  },
  computed: {
    ...mapGetters(['breakTheWallConfig', 'currentCommand', 'breakTheWallBalls']),
    backgroundStyle()
    {
      const out = { overflow: 'hidden' };
      if (this.breakTheWallConfig && this.breakTheWallConfig.backgroundImage)
        out.background = `url("${this.breakTheWallConfig.backgroundImage}") no-repeat center center/cover fixed`;
      if (this.breakTheWallConfig && this.breakTheWallConfig.backgroundColor)
        out.backgroundColor = this.breakTheWallConfig.backgroundColor.hex8 || 'white';
      return out;
    },
  },
  methods: {
    onResize()
    {
      if (!this.pixiApp)
        return;
      const elem = document.getElementById('BreakTheWall');
      this.pixiApp.renderer.resize(elem.offsetWidth, elem.offsetHeight);
      this.setTilesSize();
    },
    getTileAt(gridX, gridY)
    {
      return this.tiles[gridX * this.breakTheWallConfig.gridSize.height + gridY];
    },
    createContext()
    {
      const elem = document.getElementById('BreakTheWall');
      this.pixiLoader = PIXI.Loader.shared;

      this.pixiApp = new PIXI.Application({
        width: elem.offsetWidth,
        height: elem.offsetHeight,
        antialias: true,
        transparent: true,
        resolution: 1,
      });
      this.pixiApp.renderer.autoResize = true;

      console.log(`created WebGl context h:${this.pixiApp.renderer.view.height} w:${this.pixiApp.renderer.view.width}`);
      elem.appendChild(this.pixiApp.view);
    },
    loadAssets()
    {
      if (!this.breakTheWallConfig.foregroundImage || !this.breakTheWallConfig.ballUrl)
        return;
      this.pixiLoader.reset();
      this.pixiLoader
        .add('foregroundImage', this.breakTheWallConfig.foregroundImage)
        .add('ballImage', this.breakTheWallConfig.ballUrl)
        .load(() => {
          this.createForegroundTiles();
          this.setTilesSize();
          this.pixiApp.ticker.add((delta) => this.mainLoopTick(delta));
        });
    },
    mainLoopTick(delta)
    {
      this.tiles.forEach((val) => val.update(delta, val));
      this.dropingTiles.forEach((val) => val.update(delta, val));
      this.balls.forEach((val) => val.update(delta, val));
      this.cleanBalls();
    },
    createForegroundTiles()
    {
      this.pixiApp.stage.removeChildren();
      this.tiles = [];
      this.availableTiles = [];
      for (let x = 0; x < this.breakTheWallConfig.gridSize.width; x += 1)
        for (let y = 0; y < this.breakTheWallConfig.gridSize.height; y += 1)
        {
          const tile = {};
          tile.gridPosition = {};
          tile.gridPosition.x = x;
          tile.gridPosition.y = y;
          tile.texture = new PIXI.Texture(this.pixiLoader.resources.foregroundImage.texture);
          tile.sprite = new PIXI.Sprite(tile.texture);
          tile.update = this.tileUpdate;
          tile.destroyed = false;
          tile.destroy = (thisTile) => {
            thisTile.sprite.visible = false;
            thisTile.destroyed = true;
            if (this.breakTheWallConfig.tileDrop)
              this.addDropingTile(thisTile);
          };
          if (this.breakTheWallConfig.endBehavior === 'reloadFade')
            tile.sprite.alpha = 0;
          this.availableTiles.push(tile.gridPosition);
          this.tiles.push(tile);
          this.pixiApp.stage.addChild(tile.sprite);
        }
    },
    setTilesSize()
    {
      if (!this.pixiLoader.resources.foregroundImage)
        return;
      const tileHeight = this.pixiApp.renderer.height / this.breakTheWallConfig.gridSize.height;
      const tileWidth = this.pixiApp.renderer.width / this.breakTheWallConfig.gridSize.width;

      // Je retire 1 pixel car Pixi ne tolere strictement pas les depassement de taille du rectangle par rapport a la texture
      const sprHeight = (this.pixiLoader.resources.foregroundImage.texture.height - 1) / this.breakTheWallConfig.gridSize.height;
      const sprWidth = (this.pixiLoader.resources.foregroundImage.texture.width - 1) / this.breakTheWallConfig.gridSize.width;
      console.log(`Tiles size h:${sprHeight} w:${sprWidth}`);
      this.tiles.forEach((tile) => {
        tile.rectangle = new PIXI.Rectangle(tile.gridPosition.x * sprWidth, tile.gridPosition.y * sprHeight, sprWidth, sprHeight);
        tile.texture.frame = tile.rectangle;
        tile.sprite.height = tileHeight;
        tile.sprite.width = tileWidth;
        tile.sprite.x = tile.gridPosition.x * tileWidth;
        tile.sprite.y = tile.gridPosition.y * tileHeight;
      });
    },
    addDropingTile(originalTile)
    {
      const tile = {};
      tile.gridPosition = originalTile.gridPosition;
      tile.texture = new PIXI.Texture(originalTile.texture);
      tile.sprite = new PIXI.Sprite(tile.texture);
      tile.update = this.dropingTileUpdate;
      tile.dropSpeed = this.chance.integer({ min: 6, max: 9 });
      tile.rotationSpeed = this.chance.integer({ min: 3, max: 9 });
      tile.clockWise = this.chance.bool();
      tile.destroy = (thisTile) => {
        thisTile.sprite.visible = false;
        thisTile.destroyed = true;
      };
      const tileHeight = this.pixiApp.renderer.height / this.breakTheWallConfig.gridSize.height;
      const tileWidth = this.pixiApp.renderer.width / this.breakTheWallConfig.gridSize.width;

      // Je retire 1 pixel car Pixi ne tolere strictement pas les depassement de taille du rectangle par rapport a la texture
      const sprHeight = (this.pixiLoader.resources.foregroundImage.texture.height - 1) / this.breakTheWallConfig.gridSize.height;
      const sprWidth = (this.pixiLoader.resources.foregroundImage.texture.width - 1) / this.breakTheWallConfig.gridSize.width;
      tile.rectangle = new PIXI.Rectangle(tile.gridPosition.x * sprWidth, tile.gridPosition.y * sprHeight, sprWidth, sprHeight);
      tile.texture.frame = tile.rectangle;
      tile.sprite.height = tileHeight;
      tile.sprite.width = tileWidth;
      tile.sprite.x = tile.gridPosition.x * tileWidth;
      tile.sprite.y = tile.gridPosition.y * tileHeight;
      tile.sprite.pivot.x = tile.sprite.width / 2;
      tile.sprite.pivot.y = tile.sprite.height / 2;
      this.dropingTiles.push(tile);
      this.pixiApp.stage.addChild(tile.sprite);
    },
    addBallRandomPosition()
    {
      if (this.availableTiles.length <= 0 || this.balls.length >= this.breakTheWallConfig.MaxBalls)
        return;
      const tIDx = this.chance.integer({ min: 0, max: this.availableTiles.length - 1 });
      this.addBall(this.availableTiles[tIDx].x, this.availableTiles[tIDx].y);
      this.removeFromAvailableTiles(this.availableTiles[tIDx]);
      if (this.availableTiles.length <= 0)
        this.endWall();
    },
    addBall(gridTargetX, gridTargetY)
    {
      const tileHeight = this.pixiApp.renderer.height / this.breakTheWallConfig.gridSize.height;
      const tileWidth = this.pixiApp.renderer.width / this.breakTheWallConfig.gridSize.width;
      const ball = {};
      ball.sprite = new PIXI.Sprite(this.pixiLoader.resources.ballImage.texture);

      ball.sprite.scale.x = 0.5;
      ball.sprite.scale.y = 0.5;
      ball.target = {};
      this.setBallSize(ball);
      ball.target.gridX = gridTargetX;
      ball.target.gridY = gridTargetY;
      ball.target.x = ((tileWidth * gridTargetX) + (tileWidth / 2)) - ((this.pixiLoader.resources.ballImage.texture.width * ball.target.finalScale) / 2);
      ball.target.y = (tileHeight * gridTargetY) + (tileHeight / 2) - ((this.pixiLoader.resources.ballImage.texture.height * ball.target.finalScale) / 2);

      ball.sprite.x = this.chance.integer({ min: this.pixiApp.renderer.width * 0.25, max: this.pixiApp.renderer.width * 0.75 }); // (this.pixiApp.renderer.width / 2) - (ball.sprite.width / 2);
      ball.sprite.y = (this.pixiApp.renderer.height) - 10;
      ball.rotationSpeed = this.chance.integer({ min: 3, max: 9 });
      ball.sprite.pivot.x = ball.sprite.width / 2;
      ball.sprite.pivot.y = ball.sprite.height / 2;

      ball.step = {
        x: (ball.target.x - ball.sprite.x) / this.breakTheWallConfig.ballSpeed,
        y: (ball.target.y - ball.sprite.y) / this.breakTheWallConfig.ballSpeed,
        scale: (ball.sprite.scale.x - ball.target.finalScale) / this.breakTheWallConfig.ballSpeed,
      };
      ball.destroyed = false;
      ball.destroy = (thisBall) => { thisBall.sprite.visible = false; thisBall.destroyed = true; };
      ball.update = this.ballUpdate;
      this.balls.push(ball);
      this.pixiApp.stage.addChild(ball.sprite);
    },
    setBallSize(ball)
    {
      ball.sprite.scale.x = this.breakTheWallConfig.ballScale.start / 100;
      ball.sprite.scale.y = this.breakTheWallConfig.ballScale.start / 100;

      ball.target.finalScale = this.breakTheWallConfig.ballScale.end / 100;
    },
    ballUpdate(delta, ball)
    {
      if (ball.destroyed)
        return;
      ball.sprite.x += ball.step.x;
      ball.sprite.y += ball.step.y;
      ball.sprite.scale.x -= ball.step.scale;
      ball.sprite.scale.y -= ball.step.scale;
      ball.sprite.angle += ball.rotationSpeed;
      if (ball.sprite.scale.x <= ball.target.finalScale)
      {
        ball.destroy(ball);
        const tile = this.getTileAt(ball.target.gridX, ball.target.gridY);
        tile.destroy(tile);
      }
    },
    cleanBalls()
    {
      this.balls.forEach((ball) => {
        if (ball.destroyed)
        {
          this.balls.splice(this.balls.indexOf(ball), 1);
          this.pixiApp.stage.removeChild(ball.sprite);
        }
      });
    },
    removeFromAvailableTiles(tile)
    {
      this.availableTiles.forEach((at) => {
        if (at.x === tile.x && at.y === tile.y)
          this.availableTiles.splice(this.availableTiles.indexOf(at), 1);
      });
    },
    tileUpdate(delta, tile)
    {
      if (tile.destroyed)
        return;
      if (tile.sprite.alpha < 1)
        tile.sprite.alpha += 0.05;
    },
    dropingTileUpdate(delta, tile)
    {
      tile.sprite.y += tile.dropSpeed;
      if (tile.clockWise)
        tile.sprite.angle += tile.rotationSpeed;
      else
        tile.sprite.angle -= tile.rotationSpeed;
    },
    endWall()
    {
      console.log('End of the wall');
      if (this.breakTheWallConfig.endBehavior !== 'nothing')
      {
        console.log(`Wall reload in ${this.breakTheWallConfig.timeBeforeFade * 1000}ms with ${this.breakTheWallConfig.endBehavior}`);
        setTimeout(() => {
          this.createForegroundTiles();
          this.setTilesSize();
        }, this.breakTheWallConfig.timeBeforeFade * 1000);
      }
    },
  },
};

</script>

<style scoped>

</style>
