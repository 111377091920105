<template>
  <div :style="backgroundStyle" id="Jauge" class="h-100 d-flex justify-content-center align-items-center background w-100">
    <div class="row p-0 m-0 w-100">
      <div class="col-md-12 text-center mb-5">
        <h3 v-html="currentVote.question" :style="questionStyle"></h3>
      </div>
      <div class="col-md-8 offset-md-2 mt-5 text-center position-relative">
        <img :src="jaugeUrl" class="img-fluid"/>
        <img :src="needleUrl" :style="needleStyle" class="position-absolute needle" style="left: 15.5%;bottom: -9%; transform-origin: 80%"/>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'Jauge',
  computed: {
    ...mapGetters(['jaugeConfig', 'pollsAnswers', 'polls', 'targetID']),
    /**
     * Background style binding
     * @return {{backgroundColor, background: string}}
     */
    backgroundStyle()
    {
      const out = {};
      if (!this.jaugeConfig)
        return out;
      if (this.jaugeConfig.backgroundImage)
        out.background = `url("${this.jaugeConfig.backgroundImage}") no-repeat center center/cover fixed`;
      if (this.jaugeConfig.backgroundColor && this.jaugeConfig.backgroundColor.hex8)
        out.backgroundColor = this.jaugeConfig.backgroundColor.hex8;
      return out;
    },
    jaugeUrl()
    {
      return this.jaugeConfig.jaugeImage;
    },
    needleUrl()
    {
      return this.jaugeConfig.needleImage;
    },
    needleStyle()
    {
      if (this.pollsAnswers[this.targetID] === undefined)
        return { transform: 'rotate(0deg)' };// transform: 'rotate(180deg)'
      const med = (this.getMediumAnswer * 100) / (Object.keys(this.currentVote.choices).length - 1);
      const value = (180 / 100) * med;
      return {
        transform: `rotate(${value}deg)`,
      };
    },
    /**
     * Global font size computing
     * if the input is only composed of number, the computed will return the value + px
     * and return the full input otherwise
     * @return {string}
     */
    fontSize()
    {
      if (/^\d+$/.test(this.jaugeConfig.fontSize))
        return `${this.jaugeConfig.fontSize}px`;
      return this.jaugeConfig.fontSize;
    },
    /**
     * Vote Question text style binding
     * @return {{fontFamily: string, color: string, fontSize: (string|string)}}
     */
    questionStyle()
    {
      return {
        color: this.jaugeConfig.fontColor.hex,
        fontSize: this.fontSize,
        fontFamily: this.jaugeConfig.fontFamily,
      };
    },
    /**
     * Return the current vote depend on the command or an empty object if no vote is specified by the command
     * @return {{question: string, statsVisible: boolean, isLocked: boolean, isVisible: boolean, type: string}|*}
     */
    currentVote()
    {
      // check if polls is not empty
      if (this.polls && Object.keys(this.polls).length === 0)
        return {
          isLocked: false,
          isVisible: true,
          question: '',
          statsVisible: false,
          type: 'single',
        };
      return this.polls[this.targetID];
    },
    getMediumAnswer()
    {
      let total = 0;
      let sum = 0;
      if (this.pollsAnswers[this.targetID] === undefined)
        return 0;
      Object.entries(this.pollsAnswers[this.targetID]).forEach(([, value]) =>
      {
        value.forEach((rep) =>
        {
          total += 1;
          sum += Object.keys(this.currentVote.choices).indexOf(rep);
        });
      });
      return sum / total;
    },
  },
};
</script>

<style scoped>

.needle {
  transition: transform 1000ms;
}

</style>
