/**
 * TODO DOC
 */
const WordCloudConfig = {
  fontFamily: '',
  backgroundImage: 'https://captag-interactions.web.app/assets/FondWordCloud.png',
  backgroundColor: {
    hex: '#ffffff',
  },
  orientation: 'horizontal',
  fontColor: [
    {
      rgba: {
        r: 250,
        g: 167,
        b: 37,
        a: 1,
      },
      hex: '#FAA725',
    },
    {
      rgba: {
        r: 36,
        g: 190,
        b: 196,
        a: 2,
      },
      hex: '#24BEC4',
    },
    {
      rgba: {
        r: 0,
        g: 0,
        b: 0,
        a: 1,
      },
      hex: '#000000',
    },
  ],
};

export default WordCloudConfig;
