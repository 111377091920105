<template>
  <div v-on:mouseenter='showButton' v-on:mouseleave='hideButton'>
    <div v-bind:style='visibilityStyle' class='row' >
      <div class='col-auto'>
        <button v-on:click='toggleFullscreen' class='btn btn-primary text-light'>
          <i class='fas fa-expand'></i> Plein écran
        </button>
      </div>
      <div class='col'>
        <button class="btn btn-primary text-light" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSidebar" aria-controls="offcanvasExample"><i class='fas fa-cogs'></i> Configuration
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RightsButtons',
  data() {
    return {
      showButtons: false,
    };
  },
  computed: {
    visibilityStyle() {
      return { visibility: this.showButtons ? 'visible' : 'hidden' };
    },
  },
  methods: {
    toggleFullscreen() {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    },
    showButton() {
      this.showButtons = true;
    },
    hideButton() {
      this.showButtons = false;
    },
  },
};

</script>

<style scoped>

</style>
