import { firebaseAction } from 'vuexfire';
import firebaseReference from './FirebaseReference';

export default {
  /**
   * @brief
   * Update the given config object to the base
   * @warning The payload parameter must respect the specification defined above
   * @param payload the binded object/node specification, see definition above
   * @return nothing
   */
  async updateConfig(_, { endpoint, object })
  {
    if (!endpoint || !object)
      return;
    const fbConfigRef = await firebaseReference.configRoot;
    fbConfigRef.child(endpoint).update(object).then(() => {
      console.log(`Config ${endpoint} updated!`);
    });
  },

  async updateGeneralConfig(_, { config })
  {
    if (!config)
    {
      console.error('Missing parameter for updateGeneralConfig');
      return undefined;
    }
    const fbConfigRef = await firebaseReference.configRoot;
    return fbConfigRef.child('screenConfig/GeneralConfig').update(config).then(() => {
      console.log('General Config updated!');
    });
  },

  /**
   * @brief
   * Update the given config object to the base
   * @warning The payload parameter must respect the specification defined above
   * @param payload the binded object/node specification, see definition above
   * @return nothing
   */
  async removeConfig(_, { endpoint })
  {
    const fbConfigRef = await firebaseReference.configRoot;
    return fbConfigRef.child(endpoint).remove().then(() => {
      console.log(`Config ${endpoint} removed!`);
    });
  },
};
