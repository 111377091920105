<template>
  <div id="LiveChat" class="background h-100 d-flex justify-content-center align-items-center" :style="backgroundStyle">
    <div id="LiveChatDrawingZone" class="background" :style="chatZoneStyle">
      <transition-group id="RankingList" name="list-complete" tag="ul" class="row mb-0 px-0 mx-0 w-100" >
        <li id="messageLine" class="list-complete-item col-12 mb-2 py-2" v-for="(value) in filteredMessages" :key="value[1]" :style="messageStyle">
          <div class="row w-100">
            <div v-if="liveChatConfig.displayAuthor && liveChatConfig.displayAvatar && liveChatConfig.alignment !== 'right'" class="col-1 text-center">
              <img class="avatar" :style="avatarStyle" :src="value[0].avatar">
            </div>
            <div class="messageContent" :class="(liveChatConfig.displayAvatar ? 'col-11' : 'col-12')">
              <div class="row h-100">
                <div v-if="liveChatConfig.displayAuthor" class="col-12 text-wrap">
                  <h5 :style="authorTextStyle" class="mb-0 authorName" :class="textAlignmentClass">{{ value[0].author }}</h5>
                </div>
                <div class="col-12 text-wrap">
                  <h5 :style="textStyle" class="mb-0 text-wrap messageText" :class="textAlignmentClass" v-html="value[0].text"></h5>
                </div>
              </div>
            </div>
            <div v-if="liveChatConfig.displayAuthor && liveChatConfig.displayAvatar && liveChatConfig.alignment === 'right'" class="col-1 text-center">
              <img class="avatar" :style="avatarStyle" :src="value[0].avatar">
            </div>
          </div>
        </li>
      </transition-group>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'LiveChat',
  computed: {
    ...mapGetters(['getBorderStyle', 'liveChatConfig', 'messages']),
    textAlignmentClass()
    {
      return this.liveChatConfig.alignment || 'text-left';
    },
    filteredMessages()
    {
      const sortable = [];
      Object.keys(this.messages).forEach((messageID, index) => {
        let pushed = true;
        if (this.liveChatConfig.onlyVisible && !this.messages[messageID].isVisible)
          pushed = false;
        if (this.liveChatConfig.onlyFavorite && !this.messages[messageID].isFavorite)
          pushed = false;
        if (this.liveChatConfig.onlySelected && !this.messages[messageID].isSelected)
          pushed = false;
        if (pushed)
          sortable.push([this.messages[messageID], this.messages[messageID].createdDate]);
      });
      sortable.sort((a, b) => b[1] - a[1]);
      return sortable.slice(0, this.liveChatConfig.maxMessages).reverse();
    },
    chatZoneStyle() {
      return {
        border: (this.liveChatConfig.displayZone) ? 'solid #1AC0C6' : '',
        position: 'absolute',
        left: `${this.liveChatConfig.zone.x}%`,
        top: `${this.liveChatConfig.zone.y}%`,
        width: `${this.liveChatConfig.zone.width}%`,
        height: `${this.liveChatConfig.zone.height}%`,
        overflow: 'hidden',
      };
    },
    backgroundStyle() {
      return {
        background: `url("${this.liveChatConfig.backgroundImage}") no-repeat center center/cover fixed`,
        backgroundColor: this.liveChatConfig.backgroundColor.hex,
      };
    },
    /**
     * Global font size computing
     * if the input is only composed of number, the computed will return the value + px
     * and return the full input otherwise
     * @return {string}
     */
    fontSize()
    {
      if (/^\d+$/.test(this.liveChatConfig.fontSizeText))
        return `${this.liveChatConfig.fontSizeText}px`;
      return this.liveChatConfig.fontSizeText;
    },
    /**
     * Global font size computing
     * if the input is only composed of number, the computed will return the value + px
     * and return the full input otherwise
     * @return {string}
     */
    fontSizeAuthor()
    {
      if (/^\d+$/.test(this.liveChatConfig.fontSizeAuthor))
        return `${this.liveChatConfig.fontSizeAuthor}px`;
      return this.liveChatConfig.fontSizeAuthor;
    },
    messageStyle() {
      if (this.liveChatConfig.messageBackground)
        return this.getBorderStyle(this.liveChatConfig.border || {});
      return {};
    },
    avatarStyle()
    {
      return {
        borderRadius: '50%',
        height: '50px',
      };
    },
    textStyle()
    {
      return {
        fontFamily: this.liveChatConfig.fontFamily,
        color: this.liveChatConfig.fontColorText.hex,
        fontSize: `${this.fontSize}!important`,
      };
    },
    authorTextStyle()
    {
      return {
        fontFamily: this.liveChatConfig.fontFamily,
        color: this.liveChatConfig.fontColorAuthor.hex,
        fontSize: `${this.fontSizeAuthor}!important`,
      };
    },
  },
};

</script>

<style scoped>

.list-complete-item {
  transition: all 1s;
  display: inline-block;
  margin-right: 10px;
}

.list-complete-enter
{
  transform: translateY(100%);
}

.list-complete-leave-to
{
  transform: translateY(-100%);
}

.list-complete-leave-active {
  position: absolute;
}

#RankingList {
  position: absolute;
  bottom: 0;
}

</style>
