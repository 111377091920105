<template>
  <div class="pe-3">
    <div class="mb-3">
      <label>Couleur de fond</label>
      <InputColorPicker Label="Couleur du fond" :value="getConfigAttr('backgroundColor', {})" @change="setAttrValue('backgroundColor', $event)"/>
    </div>
    <div class="mb-3">
      <InputImageUpload title="Image de fond"  :value="getConfigAttr('backgroundImage', '')" @change="setAttrValue('backgroundImage', $event)"/>
    </div>
    <div class="mb-3">
      <label>Couleur du fond de la question</label>
      <InputColorPicker Label="Couleur du fond" :value="getConfigAttr('QuestionBackgroundColor', {})" @change="setAttrValue('QuestionBackgroundColor', $event)"/>
    </div>
    <div class="mb-3">
      <label>Couleur de la bonne réponse</label>
      <InputColorPicker Label="Couleur du fond" :value="getConfigAttr('goodAnswerColor', {})" @change="setAttrValue('goodAnswerColor', $event)"/>
    </div>
    <div class="mb-3">
      <label>Couleur de la police</label>
      <InputColorPicker Label="Couleur du fond" :value="getConfigAttr('fontColor', {})" @change="setAttrValue('fontColor', $event)"/>
    </div>
    <div class="mb-3">
      <FontSelector label="Police" :value="getConfigAttr('fontFamily', '')" @change="setAttrValue('fontFamily', $event)" :imported-fonts="googleFonts"/>
    </div>
    <div class="mb-3">
      <label for="fontSize">Taille de la question</label>
      <input :value="getConfigAttr('questionSize', 21)" @change="setAttrValue('questionSize', parseInt($event.target.value))" id="fontSize" type="number" class="form-control" placeholder="21">
    </div>
    <div class="mb-3">
      <label for="fontSize">Taille des réponses</label>
      <input :value="getConfigAttr('answerSize', 21)" @change="setAttrValue('answerSize', parseInt($event.target.value))" id="fontSize" type="number" class="form-control" placeholder="21">
    </div>
    <div class="mb-3">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="toggle-fullwidth" v-model="isTimer">
        <label class="form-check-label" for="toggle-fullwidth">Activer / Désactiver le timer</label>
      </div>
    </div>
    <Card v-if="isTimer" title="Configuration du timer" :title-size="6">
      <div class="mb-3">
        <label for="fontSize">Durée du timer</label>
        <input :value="getConfigAttr('timerDuration', 21)" @change="setAttrValue('timerDuration', parseInt($event.target.value))" id="fontSize" type="number" class="form-control" placeholder="21">
      </div>
      <div class="mb-3">
        <label>Couleur du timer</label>
        <InputColorPicker Label="Couleur du fond" :value="getConfigAttr('timerColor', {})" @change="setAttrValue('timerColor', $event)"/>
      </div>
      <div class="mb-3">
        <label  for="valueType">Alignement du timer</label>
        <select class="form-control" :value="getConfigAttr('timeAlignment', 'text-center')" @change="setAttrValue('timeAlignment', $event.target.value)" id="valueType">
          <option value="text-start">Gauche</option>
          <option value="text-center">Centre</option>
          <option value="text-end">Droite</option>
        </select>
      </div>
    </Card>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import InputImageUpload from '../../utilities/InputImageUpload.vue';
import InputColorPicker from '../../utilities/InputColorPicker.vue';
import FontSelector from '../../utilities/FontSelector.vue';
import Card from '../../utilities/Card.vue';

export default {
  name: 'VoteBoardLeftBar',
  components: {
    Card,
    InputImageUpload,
    InputColorPicker,
    FontSelector,
  },
  computed: {
    ...mapGetters(['googleFonts', 'voteBoardConfig']),
    isTimer: {
      get()
      {
        return this.voteBoardConfig.isTimer || false;
      },
      set(newValue)
      {
        this.$store.commit('setVoteBoardConfig', { ...this.voteBoardConfig, isTimer: newValue });
      },
    },
  },
  methods: {
    getConfigAttr(attrName, defaultValue)
    {
      return this.voteBoardConfig[attrName] || defaultValue;
    },
    setAttrValue(attrName, value)
    {
      const out = { ...this.voteBoardConfig };
      out[attrName] = value;
      this.$store.commit('setVoteBoardConfig', out);
    },
  },
};

</script>

<style scoped>

</style>
