<template>
  <div class="pe-3">
    <div class="mb-3">
      <FontSelector
        :label="dictionary.font"
        :imported-fonts="googleFonts"
        v-model="bodyFont"/>
    </div>
    <div class="mb-3">
      <button v-on:click="$refs.customCSSModal.open()" type="button" class="w-100 btn btn-primary text-white"><span class="fab fa-css3-alt me-3"></span>Editeur de style</button>
    </div>
    <div class="mb-3">
      <button v-on:click="$refs.addFontModal.open()" class="btn btn-info w-100 text-white">{{ dictionary.leftBar.addOrRemoveFont }}<span class="badge bg-secondary ms-2">{{ googleFontLength }}</span></button>
    </div>
    <div class="mb-3 text-center">
      <label class="">Importer / Exporter la configuration</label>
      <div class="row mt-2">
        <div class="col-md-6 text-center">
          <button class="w-100 btn btn-primary btn-secondary text-light fw-bold" v-on:click="exportConfigs">Exporter</button>
        </div>
        <div class="col-md-6 text-center">
          <button class="w-100 btn btn-primary btn-danger text-light fw-bold" v-on:click="importButtonClick">Importer</button>
          <input accept="application/JSON" ref="importHiddenInput" type="file" v-on:change="importConfigs" style="visibility: hidden">
        </div>
      </div>
    </div>
    <div class="mb-3">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="toggle-fullwidth" v-model="ignoreCommand">
        <label class="form-check-label" for="toggle-fullwidth">Ne plus écouter la télécommande<br>(A utiliser en connaissance de cause)</label>
      </div>
    </div>
    <div class="text-muted small text-center">
      <label>Version 2.1</label>
    </div>
    <CustomCodeEditorModal ref="customCSSModal" v-bind:title="dictionary.leftBar.cssEditor" mode="text/css" v-model="css" id="customCssModal" />
    <FontManagerModal ref="addFontModal" v-model="fontList"/>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import CustomCodeEditorModal from './modals/CustomCodeEditorModal.vue';
import FontManagerModal from './modals/FontManager/FontManagerModal.vue';
import FontSelector from './utilities/FontSelector.vue';
import firebaseReference from '../store/FirebaseReference';

export default {
  name: 'GeneralConfiguration',
  components: {
    CustomCodeEditorModal,
    FontSelector,
    FontManagerModal,
  },
  computed: {
    ...mapGetters([
      'dictionary',
      'customCss',
      'googleFonts',
      'generalConfig',
      'allConfig',
      'isIgnoreCommand',
    ]),
    googleFontLength()
    {
      if (this.googleFonts === undefined)
        return 0;
      return Object.keys(this.googleFonts).length;
    },
    ignoreCommand: {
      get()
      {
        return this.isIgnoreCommand | false;
      },
      set(newValue)
      {
        this.$store.commit('setIgnoreCommand', newValue || false);
      },
    },
    css: {
      get()
      {
        return this.customCss || '';
      },
      set(newCss)
      {
        this.$store.commit('setCustomCss', newCss);
      },
    },
    bodyFont: {
      get()
      {
        return this.generalConfig.bodyFont || '';
      },
      set(newValue)
      {
        this.$store.commit('setGeneralConfig', { ...this.generalConfig, bodyFont: newValue });
      },
    },
    fontList: {
      get()
      {
        return this.googleFonts || {};
      },
      set(fontData)
      {
        this.$store.commit('addGoogleFont', { fontObject: fontData });
      },
    },
  },
  methods: {
    exportConfigs() {
      const jsonData = JSON.stringify(this.allConfig, null, 4);
      const blob = new Blob([jsonData], { type: 'text/plain' });
      const e = document.createEvent('MouseEvents');
      const a = document.createElement('a');
      a.download = `config_${firebaseReference.eID}_${firebaseReference.aID}.json`;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    },
    importButtonClick() {
      this.$refs.importHiddenInput.click();
    },
    importConfigs(ev) {
      const file = ev.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const object = JSON.parse(e.target.result);
          this.$store.commit('setConfiguration', object);
          this.importNotifSuccess();
        } catch (ex)
        {
          this.importNotifError();
          console.log(`Invalid config file : ${ex.message}`);
        }
      };
      reader.readAsText(file);
    },
  },
};

</script>

<style scoped>

</style>
