/**
 * TODO DOC
 */
const RankingConfig = {
  backgroundImage: 'https://captag-interactions.web.app/assets/FondRanking.png',
  backgroundColor: {
    rgba: {
      r: 255,
      g: 255,
      b: 255,
      a: 1,
    },
    hex: '#ffffff',
  },
  border: true,
  borderColor: {
    rgba: {
      r: 250,
      g: 167,
      b: 37,
      a: 1,
    },
    hex: '#FAA725',
  },
  fontColorMain: {
    rgba: {
      r: 0,
      g: 0,
      b: 0,
      a: 1,
    },
    hex: '#000000',
  },
  fontColorAlt: {
    rgba: {
      r: 36,
      g: 190,
      b: 196,
      a: 2,
    },
    hex: '#24BEC4',
  },
  fontFamily: '',
  fontSize: '44',
  animID: '',
  teamAverage: false,
  teamData: '',
  rankingLength: 10,
  textAlign: 'text-center',
  showPoints: true,
  showPosition: true,
  refreshFreq: 5,
};

export default RankingConfig;
