<template>
  <div class='row d-flex align-items-center me-0'>
    <div class="col-12">
      <label class='form-label' for='inputImage' v-html="title"></label><span v-tooltip="'Caché si vide'" class="badge rounded-pill bg-info text-white ms-2 small"><i class="fas fa-eye-slash small"></i></span>
    </div>
    <div class='col-10'>
      <input id='inputImage' v-model="imageLink" type='text' class='form-control' placeholder='https://...'>
    </div>
    <button class='col-2 btn btn-info btn-raised text-light' v-on:click.prevent='uploadImage'><i class='fas fa-upload'></i></button>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import VueNotifications from 'vue-notifications';
import firebaseReference from '../../store/FirebaseReference';

export default {
  name: 'InputImageUpload',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    title: String,
    value: String,
  },
  computed: {
    imageLink: {
      get() {
        return this.value;
      },
      set(newLink) {
        this.$emit('change', newLink);
      },
    },
  },
  methods: {
    uploadImage() {
      window.cptgUploader.init('identifiantUnique', `${firebaseReference.eID}/interactions/${firebaseReference.aID}`, {
        apiKey: 'f2Ran1l9K1kJCAUEPWj5',
        sources: ['local'],
        lang: this.language || 'fr',
        theme: 'light',
        useEditor: false,
        forceSquareCrop: false,
        maxFileSize: 20,
      },
      (result) => {
        this.uploadSuccess();
        this.$emit('change', result.originalURL || '');
      }).open();
    },
  },
  notifications: {
    uploadSuccess: {
      type: VueNotifications.types.success,
      title: 'Image uploadé !',
      message: 'Image uploadé avec succès.',
    },
    uploadError: {
      type: VueNotifications.types.error,
      title: 'Erreur',
      message: 'Une erreur est survenue lors de l\'envois de l\'image.',
    },
  },
};

</script>

<style scoped>

</style>
