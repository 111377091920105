export default {
  setQuestionConfig(state, newConfig)
  {
    state.QuestionConfig = newConfig || {};
  },
  setHideConfig(state, newConfig)
  {
    state.HideConfig = newConfig || {};
  },
  setVoteConfig(state, newConfig)
  {
    state.VoteConfig = newConfig || {};
  },
  setBreakTheWallConfig(state, newConfig)
  {
    state.BreakTheWallConfig = newConfig || {};
  },
  setJaugeConfig(state, newConfig)
  {
    state.JaugeConfig = newConfig || {};
  },
  setWordCloudConfig(state, newConfig)
  {
    state.WordCloudConfig = newConfig || {};
  },
  setRankingConfig(state, newConfig)
  {
    state.RankingConfig = newConfig || {};
  },
  setLiveChatConfig(state, newConfig)
  {
    state.LiveChatConfig = newConfig || {};
  },
  setVoteBoardConfig(state, newConfig)
  {
    state.VoteBoardConfig = newConfig || {};
  },
};
